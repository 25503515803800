import {TextUtilities} from '../text'
export function getUrlDomain(url: string | undefined) {

	if (!url) return '';

	if (!TextUtilities.isValidUrl(url)) return url;

	const domain = new URL(url);
	return domain.hostname;

}