import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Apply: computed(() => en() ? `Apply` : `{{ ES: Apply }}`),
		InfoMsg: computed(() => en() ? '### Apply for an upcoming Pitch Contest\n\n\nThank you for your interest in EforAll. To locate available programs, we need to collect some basic information.This information will never be shared with any external third parties.'
			: '{{ ES: ### Solicite una próxima Pitch Contest\n\n\nGracias por su interés en EforAll.Para localizar los programas disponibles, necesitamos recopilar información básica.Esta información nunca se compartirá con terceros externos.}}'),
	}
}