import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		ApplicationChanges: computed(() => en() ? `Application Changes` : `{{ ES: Application Changes }}`),
		InfoMsg: computed(() => en() ? `Instructions and an invite link has been emailed to {participant-email}. If you would like to resend
				that email or copy the link and send it yourself, use the buttons below.` : `{{ ES: Instructions and an invite link has been emailed to {participant-email}. If you would like to resend
				that email or copy the link and send it yourself, use the buttons below. }}`),
		CopyLink: computed(() => en() ? `Copy Link` : `{{ ES: Copy Link }}`),
		LinkCopiedToClipboard: computed(() => en() ? `The invite link has been copied to your clipboard. Please send it to -` : `{{ ES: The invite link has been copied to your clipboard. Please send it to - }}`),
		InviteCode: computed(() => en() ? `Invite Code` : `{{ ES: Invite Code }}`),
		ResendInvite: computed(() => en() ? `Resend Invite` : `{{ ES: Resend Invite }}`),
		Reopen: computed(() => en() ? `Reopen` : `{{ ES: Reopen }}`),
		RemoveParticipantMsg: computed(() => en() ? `Are you sure you want to remove this participant's invitation?\n\n{participant-firstName} will no longer be able to join the Application.` : `{{ ES: Are you sure you want to remove this participant's invitation?\n\n{participant-firstName} will no longer be able to join the Application. }}`),
		RemoveParticipantWithMoreInfoMsg: computed(() => en() ? `Are you sure you want to remove this participant?\n\n{participant-firstName} has already completed their input for this application, removing them will delete them from your application. They will no longer be marked as a participant.` : `{{ ES: Are you sure you want to remove this participant?\n\n{participant-firstName} has already completed their input for this application, removing them will delete them from your application. They will no longer be marked as a participant. }}`),
		Title: computed(() => en() ? `Title` : `{{ ES: Title }}`),
		Withdraw: computed(() => en() ? `Withdraw` : `{{ ES: Withdraw }}`),
		
	}
}