import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		NoSurveyOpen: computed(() => en() ? `No Survey open at this time` : `No Survey open at this time`),
		AccSessionSurvey: computed(() => en() ? `Accelerator Session Survey` : `Sesión de aceleración Survey`),
	}
}