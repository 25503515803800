@if(prompt(); as prompt){
<div class="d-flex flex-column gap-2">
	<markdown class="agreement-text" [data]="prompt.markdownText"></markdown>
	<hr>
	<div>
		<h4>
			{{prompt.promptText}}
		</h4>

		@if(!prompt.completedUTC && promptAnswer()){
		<common-text-field [field]="promptAnswer()!"></common-text-field>
		}
		@else {
			<div class="white-box">
				{{prompt.promptAnswer}}
			</div>
		}
	</div>
</div>
}