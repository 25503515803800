@if(errorService.details().error) {
<pre>{{ errorService.details() | json }}</pre>
}
@else if(auth.state().status === 'signed-out') {
	<common-signed-out></common-signed-out>
}
@else() {
<router-outlet></router-outlet>
}

@if(spinnerService.current()) {
<mobile-spinner></mobile-spinner>
}