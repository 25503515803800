import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		ApplicationQuestions: computed(() => en() ? `Application Questions` : `{{ ES: Application Questions }}`),
		Question: computed(() => en() ? `Question` : `{{ ES: Question }}`),
		Of: computed(() => en() ? `of` : `{{ ES: of }}`),
		NextQuestion: computed(() => en() ? `Next Question` : `{{ ES: Next Question }}`),
		NoAccessMessage: computed(() => en() ? `Only the applicant can edit application questions. TBD` : `{{ ES: Only the applicant can edit application questions. TBD }}`),
	}
}