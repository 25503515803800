import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Activities: computed(() => en() ? `Activities` : `Actividades`),
		ActivitiesCompleted: computed(() => en() ? `Activities Completed` : `Actividades completadas`),
		Completed: computed(() => en() ? `Completed` : `Completado`),
		CompletePreviousActivityMsg: computed(() => en() ? `This activity will be enabled once you have completed the previous activity.` : `Esta actividad estará disponible una vez que hayas completado la actividad anterior.`),
		Contains: computed(() => en() ? `contains` : `contiene`),
		Course: computed(() => en() ? `Course` : `Curso`),
		Courses: computed(() => en() ? `Courses` : `Cursos`),
		Feedback: computed(() => en() ? `Feedback` : `Retroalimentación`),
		Incomplete: computed(() => en() ? `Incomplete` : `Incompleto`),
		Message: computed(() => en() ? `Message` : `Mensaje`),
		Module: computed(() => en() ? `Module` : `Módulo`),
		NoActivities: computed(() => en() ? `No Activities` : `No hay actividades`),
		NoDataLoaded: computed(() => en() ? `No data loaded` : `No hay datos cargados`),
		NotEnrolledMsg: computed(() => en() ? `You are not enrolled in this course.` : `Usted no está inscrito en este curso.`),
		NotStarted: computed(() => en() ? `Not started` : `No ha comenzado`),
		Of: computed(() => en() ? `of` : `de`),
		Optional: computed(() => en() ? `Optional` : `Opcional`),
		Prompt: computed(() => en() ? `Prompt` : `Reflexiona`),
		Quiz: computed(() => en() ? `Quiz` : `Quiz`),
		Section: computed(() => en() ? `Section` : `Sección`),
		Sections: computed(() => en() ? `Sections` : `Secciónes`),
		This: computed(() => en() ? `This` : `Este`),
		Topic: computed(() => en() ? `Topic` : `Tema`),
		Video: computed(() => en() ? `Video` : `Video`),
		Workbook: computed(() => en() ? `Workbook` : `Cuaderno de Actividades`),

	};
}