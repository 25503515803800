<div class="d-flex flex-column gap-3">
	<common-button-column>
		<button class="btn btn-large"
			[ngClass]="{  'btn-primary': primaryBtn() === 'instructions',  'btn-standard': primaryBtn() !== 'instructions',}"
			(click)="openInstructions()">{{labels.Instructions()}}</button>

	</common-button-column>

	<common-select-field [field]="form.doingItFullTime()"></common-select-field>
	<common-text-field [field]="form.title()"></common-text-field>
	<common-text-field [field]="form.firstName()"></common-text-field>
	<common-text-field [field]="form.middleInit()"></common-text-field>
	<common-text-field [field]="form.lastName()"></common-text-field>
	<!-- Gender -->
	<common-select-field [field]="form.gender()"></common-select-field>
	@if(showOtherGender()){
	<common-text-field [field]="form.genderOther()"></common-text-field>
	}



	<!-- Hispanic or Latino -->
	<common-select-field [field]="form.hispanicOrLatino()"></common-select-field>


	<!-- Races -->
	<common-multiselect-field [field]="form.races()"></common-multiselect-field>


	<!-- Veteran -->
	<common-select-field [field]="form.veteran()"></common-select-field>



	<!-- Immigrant -->
	<common-select-field [field]="form.immigrant()"></common-select-field>


	<!-- Native English Speaker -->
	<common-select-field [field]="form.nativeEnglish()"></common-select-field>



	<!-- Native Spanish Speaker -->
	<common-select-field [field]="form.nativeSpanish()"></common-select-field>


	<!-- Birthday -->
	<common-date-time-field [field]="form.birthday()"></common-date-time-field>


	<common-select-field [field]="form.employmentStatus()"></common-select-field>
	<common-select-field [field]="form.headOfHousehold()"></common-select-field>
	<common-number-field [field]="form.householdIncome()"></common-number-field>

	<common-phone-field [field]="form.phone()"></common-phone-field>
	<common-address-field [field]="form.address()"></common-address-field>

	<common-button-column>
		@if(form.applicationService.application()?.isCoParticipant){
		<button class="btn btn-large"
			[ngClass]="{  'btn-primary': primaryBtn() === 'next-step',  'btn-standard': primaryBtn() !== 'next-step',}"
			(click)="form.applicationService.navigateToApplication()"
			[disabled]="!form.aboutYouComplete()">{{labels.Complete()}}</button>
		}
		@else{
		<button class="btn btn-large"
			[ngClass]="{  'btn-primary': primaryBtn() === 'next-step',  'btn-standard': primaryBtn() !== 'next-step',}"
			(click)="form.applicationService.navigateTo({step:'your-team'})"
			[disabled]="!form.aboutYouComplete()">{{labels.NextStep()}}</button>
		}
	</common-button-column>
</div>