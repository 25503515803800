import { Injectable, computed, inject, signal } from "@angular/core";
import { AddressData, CommonAddressField, CommonAddressFieldConfig, CommonGeneralFieldConfig, CommonNumberField, CommonNumberFieldConfig, CommonOptionsFieldConfig, CommonPhoneField, CommonSelectField, CommonTextField, CommonTextFieldConfig, Option, UrlService } from "@eforall/common";
import { DomainDataService, FuncService } from '../../../../services';
import { ApplicationService } from "../application.service";
import { getLabels } from "./your-business.page.labels";

@Injectable({ providedIn: 'root' })
export class ApplicationYourBusinessFormService {

	private readonly applicationService = inject(ApplicationService);
	private readonly func = inject(FuncService);
	private readonly urlService = inject(UrlService);
	private readonly domainData = inject(DomainDataService);
	public readonly labels = getLabels(this.urlService);

	readonly businessName = computed<CommonTextField>(() => ({
		config: signal<CommonTextFieldConfig>({ label: 'Business Name', min: 5, max: 40, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.applicationService.application()?.application.companyName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const application = this.applicationService.application();
			if (application) {
				await this.func.application.form.setCompanyName({ applicationId: application.application.applicationId, companyName: value });
				this.applicationService.app.setApplicationData({ ...application.application, companyName: value });
				this.applicationService.app.setBusinessData({ ...application.business, longName: value });
			}
		},
		error: signal(''),
	}));


	private readonly businessTypeOptions = computed(() => {
		const domainData = this.domainData.data();
		return domainData.companyTypes.reduce((a: Option<number>[], type) => {
			a.push({ text: type.label, value: type.companyTypeId, });
			return a;
		}, []).sort((a, b) => a.text < b.text ? -1 : 1);
	});

	public readonly businessType  = computed<CommonSelectField<number>>(() => ({
		config: signal<CommonOptionsFieldConfig<number>>({ label: 'Business Type', options: this.businessTypeOptions(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.applicationService.application()?.application.companyTypeId || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {

			const application = this.applicationService.application();
			if (application) {
				await this.func.application.form.setCompanyTypeId({ applicationId: application.application.applicationId, companyTypeId: value });
				this.applicationService.app.setApplicationData({ ...application.application, companyTypeId: value });
				this.applicationService.app.setBusinessData({ ...application.business, companyTypeId: value });

			}
		},
		error: signal(''),
	}));


	private readonly industryOptions = computed(() => {
		return this.domainData.data().industries.reduce((a: Option<number>[], type) => {
			a.push({ text: type.label, value: type.industryId, });
			return a;
		}, []).sort((a, b) => a.text < b.text ? -1 : 1);
	});

	public readonly industry  = computed<CommonSelectField<number>>(() => ({
		config: signal<CommonOptionsFieldConfig<number>>({ label: 'Industry', options: this.industryOptions(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.applicationService.application()?.business.industryId || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {

			const application = this.applicationService.application();
			if (application) {
				await this.func.application.form.setCompanyIndustryId({ applicationId: application.application.applicationId, industryId: value });
				this.applicationService.app.setBusinessData({ ...application.business, industryId: value });
			}
		},
		error: signal(''),
	}));


	private readonly offeringOptions = [
		{ value: 'Products', text: 'Products' },
		{ value: 'Services', text: 'Services' },
		{ value: 'Both', text: 'Products and Services' },
	];

	public readonly offering  = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Offering', options: this.offeringOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.applicationService.application()?.business.offerings || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const application = this.applicationService.application();
			if (application) {
				await this.func.application.form.setCompanyOfferings({ applicationId: application.application.applicationId, offerings: value });
				this.applicationService.app.setBusinessData({ ...application.business, offerings: value });
			}
		},
		error: signal(''),
	}));



	readonly annualRevenue  = computed<CommonNumberField>(() => ({
		config: signal<CommonNumberFieldConfig>({ label: 'Annual Revenue (last year)', max: Math.pow(2, 32) - 1, required: true, isSpanish: this.urlService.isSpanish(), type: 'dollar', }),
		actualValue: computed(() => this.applicationService.application()?.application.annualRevenue ?? undefined),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			if (value != undefined) {
				const application = this.applicationService.application();
				if (application) {
					await this.func.application.form.setAnnualRevenue({ applicationId: application.application.applicationId, annualRevenue: value });
					this.applicationService.app.setApplicationData({ ...application.application, annualRevenue: value });
				}
			}
		},
		error: signal(''),
	}));


	readonly numFullTime  = computed<CommonNumberField>(() => ({
		config: signal<CommonNumberFieldConfig>({ label: 'Number of Full-Time Employees (include owners)', max: Math.pow(2, 16) - 1, required: true, isSpanish: this.urlService.isSpanish(), type: 'numeric', }),
		actualValue: computed(() => this.applicationService.application()?.application.numFullTimeEmployees ?? undefined),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			if (value != undefined) {
				const application = this.applicationService.application();
				if (application) {
					await this.func.application.form.setFullTimeEmployees({ applicationId: application.application.applicationId, numFullTimeEmployees: value });
					this.applicationService.app.setApplicationData({ ...application.application, numFullTimeEmployees: value });
				}
			}
			this.numFullTime().saving.set(false);
		},
		error: signal(''),
	}));


	readonly numPartTime  = computed<CommonNumberField>(() => ({
		config: signal<CommonNumberFieldConfig>({ label: 'Number of Part-Time Employees (include owners)', max: Math.pow(2, 16) - 1, required: true, isSpanish: this.urlService.isSpanish(), type: 'numeric', }),
		actualValue: computed(() => this.applicationService.application()?.application.numPartTimeEmployees ?? undefined),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			if (value != undefined) {
				const application = this.applicationService.application();
				if (application) {
					await this.func.application.form.setPartTimeEmployees({ applicationId: application.application.applicationId, numPartTimeEmployees: value });
					this.applicationService.app.setApplicationData({ ...application.application, numPartTimeEmployees: value });
				}
			}
		},
		error: signal(''),
	}));

	readonly numContractors  = computed<CommonNumberField>(() => ({
		config: signal<CommonNumberFieldConfig>({ label: 'Number of Contractors (IRS form 1099)', max: Math.pow(2, 16) - 1, required: true, isSpanish: this.urlService.isSpanish(), type: 'numeric', }),
		actualValue: computed(() => this.applicationService.application()?.application.numContractors ?? undefined),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			if (value != undefined) {
				const application = this.applicationService.application();
				if (application) {
					await this.func.application.form.setContractors({ applicationId: application.application.applicationId, numContractors: value });
					this.applicationService.app.setApplicationData({ ...application.application, numContractors: value });
				}
			}
		},
		error: signal(''),
	}));

	readonly businessPhone = computed<CommonPhoneField>(() => ({
		config: signal<CommonGeneralFieldConfig>({ label: 'Business Phone', required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.applicationService.application()?.business.phone || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const application = this.applicationService.application();
			if (value && application) {
				await this.func.application.form.setCompanyPhone({ applicationId: application.application.applicationId, phone: value });
				this.applicationService.app.setBusinessData({ ...application.business, phone: value });
			}
		},
		error: signal(''),
	}));


	readonly businessAddress  = computed<CommonAddressField>(() => ({
		config: signal<CommonAddressFieldConfig>({ label: 'Business Address', isSpanish: false, required: false }),
		actualValue: computed(() => {
			const application = this.applicationService.application();
			if (application) {
				return { inUS: application.business.inUS, latitude: application.business.placeLatitude, longitude: application.business.placeLongitude, placeAddress: application.business.placeAddress || '', placeId: application.business.placeId, streetAddress: application.business.address || '', zipId: application.business.zipId, };
			}
			return undefined;
		}),
		pendingValue: signal<AddressData | undefined | null>(null),
		saving: signal(false),
		save: async (value: AddressData | undefined) => {
			const application = this.applicationService.application();

			if (application) {
				const address: AddressData = {
					inUS: value?.inUS || true,
					zipId: value?.zipId,
					streetAddress: value?.streetAddress || '',
					placeAddress: value?.placeAddress || '',
					placeId: value?.placeId,
					latitude: value?.latitude,
					longitude: value?.longitude,
				};

				await this.func.application.form.setBusinessAddress({ applicationId: application.application.applicationId, address });

				this.applicationService.app.setBusinessData({
					...application.business,
					...address
				});

			}
		},
		error: signal(''),
	}));


	public aboutBusinessComplete = computed<boolean>(() => {

		const hasError = !!this.businessName().error()
			|| !!this.businessType().error()
			|| !!this.industry().error()
			|| !!this.offering().error()
			|| !!this.annualRevenue().error()
			|| !!this.numFullTime().error()
			|| !!this.numPartTime().error()
			|| !!this.numContractors().error()
			|| !!this.businessPhone().error();

		const application = this.applicationService.application();

		const validValues = application ? !!application.application.companyName
			&& !!application.application.companyTypeId
			&& !!application.business.industryId
			&& !!application.business.offerings
			&& application.application.annualRevenue != undefined
			&& application.application.numFullTimeEmployees != undefined
			&& application.application.numPartTimeEmployees != undefined
			&& application.application.numContractors != undefined
			&& !!application.business.phone : false;

		return !hasError && validValues;

	});

}