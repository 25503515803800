import { Injectable, computed, inject, signal } from "@angular/core";
import { CommonTextFieldConfig, UrlService } from "@eforall/common";
import { FuncService } from '../../../../services';
import { ApplicationService } from "../application.service";
import { getLabels } from "./question.page.labels";

@Injectable({ providedIn: 'root' })
export class ApplicationQuestionFormService {

	private readonly applicationService = inject(ApplicationService);
	private readonly func = inject(FuncService);
	private readonly urlService = inject(UrlService);
	public readonly labels = getLabels(this.urlService);

	readonly question = computed(() => {
		const selectedQuestion = this.applicationService.selectedQuestion();
		const multiLine = selectedQuestion?.question.questionTypeId == 3 || selectedQuestion?.question.questionTypeId == 2 || selectedQuestion?.question.questionTypeId == 7 ? true : false;

		return {
			config: signal<CommonTextFieldConfig>({
				label: this.labels.form.Answer(),
				min: selectedQuestion?.question.isRequired ? 1 : 0,
				max: selectedQuestion?.question.questionTypeId == 3 ? 1000 : selectedQuestion?.question.questionTypeId == 2 ? 3000 : 100,
				fixCase: true,
				multiLine,
				minHeight: multiLine ? 15 : 10,
				isSpanish: this.urlService.isSpanish()
			}),
			actualValue: computed(() => this.applicationService.selectedQuestion()?.answer || ''),
			pendingValue: signal<string | null>(null),
			saving: signal(false),
			save: async (value: string) => {

				const selectedQuestion = this.applicationService.selectedQuestion();

				if (selectedQuestion) {
					await this.func.application.form.setAnswer({ applicationId: this.applicationService.applicationId()!, questionId: selectedQuestion.question.questionId, value });
					selectedQuestion.answer = value;
					const answers = [...this.applicationService.answers()];
					const answer = answers.find(s => s.question.questionId == selectedQuestion.question.questionId)!;
					answer.answer = value;
					this.applicationService.answers.set(answers);
				}
			},
			error: signal(''),
		};
	})
}