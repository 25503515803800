<!-- @let flyoutContent = flyoutService.content(); Angular 18 -->

@if(flyoutService.content(); as flyoutContent)
{
@if(flyoutContent.type === 'UserMenu'){
<public-user-menu-flyout-part></public-user-menu-flyout-part>
}
@if(flyoutContent.type === 'Message'){
<public-message-flyout-part [message]="flyoutContent.data" [header]="flyoutContent.header"></public-message-flyout-part>
}
@if(flyoutContent.type === 'ApplicationSubmission'){
<application-submit-flyout-part></application-submit-flyout-part>
}
@if(flyoutContent.type === 'ApplicationMakeChanges'){
<application-make-changes-flyout-part></application-make-changes-flyout-part>
}
@if(flyoutContent.type === 'ApplicationReadonly'){
<application-readonly-flyout-part></application-readonly-flyout-part>
}
@if(flyoutContent.type === 'ApplicationParticipant'){
<application-participant-flyout-part [participant]="flyoutContent.data"></application-participant-flyout-part>
}
@if(flyoutContent.type === 'CalendarEvent'){
<calendar-event-flyout-part [event]="flyoutContent.data"></calendar-event-flyout-part>
}
@if(flyoutContent.type === 'GoalComments'){
<goal-comments-flyout-part [comments]="flyoutContent.data"></goal-comments-flyout-part>
}
}