import { Injectable, computed, inject, signal } from "@angular/core";
import { Router } from "@angular/router";
import { UserApplication } from "@app-interfaces";
import { UrlService } from "@eforall/common";
import { ApplicationStatusId, EligibleProgram, LanguageId } from "@interfaces";
import { AppService, FlyoutService, FuncService } from '../../../services';
import { ApplicationApplyFormService } from "./apply-form.service";
import { getLabels } from './apply.part.labels';
import { BusinessForApplication, OPEN_STATUS, PendingApplication } from "./interfaces";


@Injectable({ providedIn: 'root' })
export class ApplicationApplyService {

	private readonly func = inject(FuncService);
	private readonly app = inject(AppService);
	public readonly form = inject(ApplicationApplyFormService);
	private readonly flyoutService = inject(FlyoutService);
	private router = inject(Router);
	public urlService = inject(UrlService);
	public labels = getLabels(this.urlService);


	private readonly type = signal<'Acc' | 'Pic' | undefined>(undefined);

	public continueClicked = signal(false);

	public canContinue = computed(() => {
		return this.form.homeAddress().actualValue()?.zipId && (
			this.form.businessLocation().pendingValue() == 'N'
			|| (
				this.form.businessLocation().pendingValue() == 'Y' && this.form.businessAddress().actualValue()?.zipId
			)
		);
	});


	public pendingAppls = computed<PendingApplication[]>(() => {

		const type = this.type();

		let applications: UserApplication[] = [];

		if (type) {
			if (type == 'Acc') applications = this.app.data().userApplications.filter(appl => !!appl.application.accId && appl.application.applicationStatusId == ApplicationStatusId.AcceptPending);
			else applications = this.app.data().userApplications.filter(appl => !!appl.application.picId && appl.application.applicationStatusId == ApplicationStatusId.AcceptPending);
		}

		return applications.reduce((a: PendingApplication[], appl) => {
			a.push({
				name: appl.application.programInstance + ' ' + appl.application.programName,
				siteName: appl.application.siteName,
				deadline: appl.deadline,
				language: appl.application.languageId == LanguageId.Spanish ? 'Espanol' : 'English',
				siteEmail: appl.application.siteEmail,
				applicationId: appl.application.applicationId,
			});

			return a;
		}, [])
	});

	public hasOpenApplications = computed(() => {
		const type = this.type();
		let applications: UserApplication[] = [];

		if (type) {
			if (type == 'Acc') applications = this.app.data().userApplications.filter(appl => !!appl.application.accId && OPEN_STATUS.includes(appl.application.applicationStatusId));
			else applications = this.app.data().userApplications.filter(appl => !!appl.application.picId && OPEN_STATUS.includes(appl.application.applicationStatusId));
		}
		return applications.length ? true : false;
	});



	public eligiblePrograms = signal<{ messageLabels: string[], eligiblePrograms: EligibleProgram[] }>({ messageLabels: [], eligiblePrograms: [] });

	public selectedApplicationProgramInstance = signal<EligibleProgram | undefined>(undefined);
	public businesses = signal<BusinessForApplication[]>([]);



	public onInit(type: 'Acc' | 'Pic') {
		this.reset();
		this.type.set(type);
	}


	reset() {
		this.form.resetFields();
		this.type.set(undefined);
		this.continueClicked.set(false);
		this.selectedApplicationProgramInstance.set(undefined);
		this.eligiblePrograms.set({ messageLabels: [], eligiblePrograms: [] });
	}


	async continue() {
		this.continueClicked.set(true);
		const homeZipId = this.form.homeAddress().actualValue()?.zipId;
		const type = this.type();
		if (type && homeZipId) {
			const businessZipId = this.form.businessAddress().actualValue()?.zipId;
			const eligiblePrograms = await this.func.applications.getEligibleApplications({
				type,
				businessZipId,
				homeZipId,
			});

			this.eligiblePrograms.set(eligiblePrograms);
		}
	}


	async applyForAProgram(program: EligibleProgram) {
		if (program.dialogMessageLabel) {
			const message = this.labels.messages[program.dialogMessageLabel]!();
			if (message) this.flyoutService.showMarkdownMessage(message, this.labels.Message());
		}
		else {
			//
			// Check if the user has an already existing application for the program instance.
			// If yes then navigate to it.
			// If no then return the list of companies and other details for the user to select from.
			//

			const application = this.app.data().userApplications.find(app => (app.application.accId && app.application.accId == program.accId) || (app.application.picId && app.application.picId == program.picId));

			if (application) {
				this.navigateToApplication(program.languageId, application.application.applicationId);
			}
			else {
				const { companies } = await this.func.applications.determineIfUserCanApply({ accId: program.accId, picId: program.picId });

				const businesses = this.app.data().businesses.reduce((a: BusinessForApplication[], company) => {
					const companyFound = companies.find(c => c.companyId == company.companyId);

					a.push({
						companyId: company.companyId,
						companyName: company.longName,
						companyTypeId: company.companyTypeId,
						industryId: company.industryId,
						title: companyFound?.title || '',
						priorApplicant: companyFound?.priorApplicant,
					});

					return a;
				}, []);

				this.businesses.set([...businesses]);

			}

			this.selectedApplicationProgramInstance.set(program);

		}
	}

	private navigateToApplication(languageId: LanguageId, applicationId: number) {

		if (languageId !== this.urlService.languageId()) {
			this.urlService.setLanguage(languageId);
		}

		const url = `/applications/${applicationId}`;

		this.router.navigate([this.urlService.withCurrentLanguage(url)]);
	}



	public async createCompanyAndAddApplication(values: { companyName: string, industryId: number, companyTypeId: number, title: string, }) {
		const companyId = await this.func.company.addCompanyAndPosition({ companyName: values.companyName, companyTypeId: values.companyTypeId, industryId: values.industryId, title: values.title });

		if (companyId) {
			await this.addApplication(companyId, values.title);
		}
	}


	public async addApplication(companyId: number, title: string) {
		const programInstance = this.selectedApplicationProgramInstance();
		if (!programInstance) return;

		const values = {
			accId: programInstance.accId,
			picId: programInstance.picId,
			companyId,
			title,
		};

		const applicationId = await this.func.application.addApplication(values);
		await this.app.loadData();

		if (applicationId) this.navigateToApplication(programInstance.languageId, applicationId);

	}


	public async updateCompanyAndPosition(companyId: number, longName: string, companyTypeId: number, industryId: number, title: string) {
		await this.func.company.setCompanyAndPosition({ companyId, longName, companyTypeId, industryId, title });
	}

}