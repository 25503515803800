import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Activity: computed(() => en() ? `Activity` : `Actividad`),
		ActivityNotAvailableYetMsg: computed(() => en() ? `This activity is not available yet. It will be made available on ` : `Esta actividad no está disponible todavía. Estará disponible en`),
		CompleteCurrentActivityBeforeMsg: computed(() => en() ? `Please complete the current activity before moving to the next activity.` : `Por favor completa la actividad actual antes de pasar a la siguiente actividad.`),
		CompletePriorRequiredActivitiesMsg: computed(() => en() ? `Please complete all prior required activities. \n\n\n Resume Incomplete activity:` : `Por favor, completa todas las actividades previas requeridas. Reanuda la actividad incompleta:`),
		Courses: computed(() => en() ? `Courses` : `Cursos`),
		Information: computed(() => en() ? `Information` : `Información`),
		LastLeftOffAtVideo: computed(() => en() ? `You last left off at {minutes} minutes.` : `Te quedaste en {minutes} minutos.`),
		Message: computed(() => en() ? `Message` : `Mensaje`),
		Module: computed(() => en() ? `Module` : `Módulo`),
		MoreInfo: computed(() => en() ? `More Info` : `Mas Información`),
		NoActivity: computed(() => en() ? `No Activity` : `No hay actividad`),
		NotEnrolledActivityMsg: computed(() => en() ? `You are not enrolled in this activity.` : `No estás inscrito en esta actividad.`),
		Optional: computed(() => en() ? `Optional` : `Opcional`),
		OptionalActivity: computed(() => en() ? `Optional Activity` : `Actividad Opcional`),
		ProvideFeedbackBeforeNextActivity: computed(() => en() ? `Please provide Feedback before moving to the next activity.` : `Por favor, provee retroalimentación antes de pasar a la siguiente actividad.`),
		ProvidePromptBeforeNextActivity: computed(() => en() ? `Please complete the Prompt before moving to the next activity.` : `Por favor completa la reflexión antes de pasar a la siguiente actividad.`),
		ProvideVideoBeforeNextActivity: computed(() => en() ? `Please complete the Video activity before moving to the next activity.` : `Por favor, completa la actividad del vídeo antes de pasar a la siguiente actividad.`),
		ProvideWorkbookBeforeNextActivity: computed(() => en() ? `Please complete the Workbook activity before moving to the next activity.` : `Por favor, completa la actividad del cuaderno de trabajo antes de pasar a la siguiente actividad.`),
		Resume: computed(() => en() ? `Resume` : `Reanudar`),
		Section: computed(() => en() ? `Section` : `Sección`),
		Topic: computed(() => en() ? `Topic` : `Tema`),
		WasThisContentHelpful: computed(() => en() ? `Was this content helpful?` : `¿Fue útil este contenido?`),

	};
}