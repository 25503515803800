<mobile-page-part>

	<div class="info-content">
		<h3>{{applicationInfoContent()}}</h3>
	</div>

	@if (applicationsList()) {
	<mobile-list [items]="applicationsList()"></mobile-list>
	}

	@if (!applicationsList().length) {
	<div class="d-flex flex-column gap-2">
		<h3>Start an Application</h3>
		<div>Learn about our Programs and start an application today!</div>
		<common-button-row [center]="true">
			<button class="btn btn-primary btn-large" routerLink="/programs">{{labels.LearnMore()}}</button>
		</common-button-row>
	</div>
	<br>
	}

	<h3>{{labels.GotInviteCode()}}</h3>
	<common-button-column>
		<button class="btn btn-standard btn-large"
			[routerLink]="urlService.withCurrentLanguage('applications/join')">{{labels.JoinApplication()}}</button>
	</common-button-column>

</mobile-page-part>