@if(feedback(); as feedback){

<div class="d-flex flex-column gap-2">

	<common-smiley-rating [rating]="response.feedbackRating" [disabled]="!!feedback.completedUTC" (updateRating)="setRating($event)"></common-smiley-rating>


	@if(!feedback.completedUTC && feedbackComment()){
	<common-text-field [field]="feedbackComment()!"></common-text-field>
	}
	@else {
		<div class="white-box">
			<h4>{{labels.Comments()}}:</h4>
			{{feedback.comments ?? labels.NoComments()}}
		</div>
	}

</div>
}