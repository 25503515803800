import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		// Materials: computed(() => en() ? `Materials` : `Materiales`),
		// Topics: computed(() => en() ? `Topic(s)` : `Tema(s)`),
	};
}