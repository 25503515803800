import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Instructions: computed(() => en() ? `Instructions` : `{{ ES: Instructions }}`),
		InstructionMessage: computed(() => en() ? `Please tell us about your current business or business idea. No individual/personal information will be shared with any external third party without your authorization. EforAll will only use aggregated (non-personally identifiable) data for fundraising purposes so our nonprofit can continue to offer our programs at no cost.` : `{{ ES: Please tell us about your current business or business idea. No individual/personal information will be shared with any external third party without your authorization. EforAll will only use aggregated (non-personally identifiable) data for fundraising purposes so our nonprofit can continue to offer our programs at no cost. }}`),
		NextStep: computed(() => en() ? `Next Step` : `{{ ES: Next Step }}`),
		AboutYourBusiness: computed(() => en() ? `About Your Business` : `{{ ES: About Your Business }}`),
		NoAccessMessage: computed(() => en() ? `Only the applicant can edit business details. TBD` : `{{ ES: Only the applicant can edit business details. TBD }}`),
	}
}