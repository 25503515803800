import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { getLabels } from './courses.page.labels';


@Component({
	selector: 'courses-page',
	imports: [
		MobilePagePart,
		RouterLink,
	],
	templateUrl: './courses.page.html'
})

export class LearningCoursesPage {
	private frame = inject(MobileFrameService);
	public labels = getLabels(this.frame.urlService);


	constructor() {
		this.frame.setUrlMetadata({
			url: '/learning/courses',
			backUrl: '/learning',
			pageName: computed(() => `Courses`),
			headerText: computed(() => `Courses`),
		});
	}

}