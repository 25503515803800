import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		// Materials: computed(() => en() ? `Materials` : `Materiales`),
		MdNoMaterialsbefore48hoursMsg: computed(() => en() ? `The course materials for this topic will be made available on ` : `Los materiales del curso para este tema estarán disponibles el `),
		MdSurveyAvailableMsg: computed(() => en() ? `Take survey! Available for {{available-for}}.` : `Realizar encuesta! Disponible para {{available-for}}.`),
		MdSurveyMarkedAbsentMsg: computed(() => en() ? `You cannot take this survey because you were marked absent. If you need assistance, reach out to the Program Manager at {{pm-email}}.` : `No puede realizar esta encuesta porque se le marcó como ausente. Si necesita ayuda, comuníquese con el gerente del programa en {{pm-email}}.`),
		MdSurveyMissedMsg: computed(() => en() ? `You have missed the deadline to complete this survey. The deadline was {{deadline}} and it is now {{now}}.` : `No cumplió con la fecha límite para completar esta encuesta. La fecha límite era el {{deadline}} y ahora es el {{now}}.`),
		MdSurveyNotAvailableMsg: computed(() => en() ? `The Program Manager has not made this survey available to you. If you need assistance, reach out to the Program Manager at {{pm-email}}.` : `El administrador del programa no ha puesto esta encuesta a su disposición. Si necesita ayuda, comuníquese con el gerente del programa en {{pm-email}}.`),
		SessionNoTopicsMsg: computed(() => en() ? `This session does not have any topics.` : `Esta sesión no tiene ningún tema.`),
		SessionTopicNoMaterialsMsg: computed(() => en() ? `This topic does not have any course material.` : `Este tema no tiene ningún material de curso.`),
		SurveyCompleteMsg: computed(() => en() ? `Thank you. You have succesfully completed this survey.` : `Gracias. Ha completado con éxito esta encuesta.`),
		// Topics: computed(() => en() ? `Topic(s)` : `Tema(s)`),
	};
}