import { Component, computed, inject, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonTextFieldConfig, CommonTextFieldWidget, UrlService } from '@eforall/common';
import { CourseActivityPrompt } from '@interfaces';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { getLabels } from './prompt.part.labels';


@Component({
	selector: 'learning-prompt-part',
	imports: [
		MarkdownModule,
		FormsModule,
		CommonTextFieldWidget,
	],
	providers: [provideMarkdown()],
	templateUrl: './prompt.part.html'
})

export class LearningPromptPart {

	public urlService = inject(UrlService);
	public labels = getLabels(this.urlService);

	public readonly prompt = input.required<CourseActivityPrompt>();
	public readonly promptAnswered = output<string>();


	readonly promptAnswer = computed(() => {
		const prompt = this.prompt();
		if (prompt.completedUTC) return undefined;

		return {
			config: signal<CommonTextFieldConfig>({
				label: this.labels.Answer(),
				min: prompt.responseMinLength ?? 1,
				max: prompt.responseMaxLength ?? 1000,
				fixCase: true,
				multiLine: true,
				minHeight: 12,
				isSpanish: this.urlService.isSpanish()
			}),
			actualValue: computed(() => this.prompt().promptAnswer || ''),
			pendingValue: signal<string | null>(null),
			saving: signal(false),
			save: async (value: string) => {
				this.promptAnswered.emit(value);
			},
			error: signal(''),
		};
	});
}