import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, CommonButtonRowWidget, CommonMessageWidget, UrlService } from '@eforall/common';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { getLabels as messageGetLabels } from '../apply.part.labels';
import { ApplicationApplyService } from '../apply.service';
import { getLabels } from './programs.part.labels';


@Component({
	selector: 'application-programs-part',
	imports: [
		MarkdownModule,
		CommonButtonRowWidget,
		RouterLink,
		CommonButtonColumnWidget,
		CommonMessageWidget,
	],
	providers: [provideMarkdown()],
	templateUrl: './programs.part.html'
})

export class ApplicationProgramsPart {

	public applyService = inject(ApplicationApplyService);
	public urlService = inject(UrlService);
	public messageLabels = messageGetLabels(this.urlService);
	public labels = getLabels(this.urlService);
}