import { UrlService } from '@eforall/common';
import { computed } from '@angular/core';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		SignOut: computed(() => en() ? `Sign Out` : `Desconectar`),
		SwitchLanguage: computed(() => en() ? `Cambiar al español` : `Switch to English`),
	};
}