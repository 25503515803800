import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ErrorService } from '@eforall/common';
import { MobileFrame, MobileLayoutService, MobilePanelPart } from '@eforall/mobile';


@Component({
	selector: 'public-mobile-frame',
	imports: [
		RouterOutlet,
		MobileFrame,
		MobilePanelPart,
	],
	templateUrl: './public-mobile.frame.html',
	styleUrl: './public-mobile.frame.scss'
})
export class PublicMobileFrame {

	public layout = inject(MobileLayoutService);
	public errorService = inject(ErrorService);


}
