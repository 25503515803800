import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMessageWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { AccTeamCohort } from '@interfaces';
import { FuncService } from '../../services';
import { getLabels } from './cohort.page.labels';
import { getIconForIndustry } from './get-icon-for-industry';


@Component({
	selector: 'cohort-page',
	imports: [
		MobilePagePart,
		CommonMessageWidget,
		MobileListWidget,
		FormsModule,
	],
	templateUrl: './cohort.page.html'
})

export class CohortPage implements OnInit {

	private frame = inject(MobileFrameService);
	private func = inject(FuncService);
	public util = inject(UtilityService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	public urlService = inject(UrlService);
	public labels = getLabels(this.urlService);


	public accTeamCohort = signal<AccTeamCohort | undefined>(undefined);


	public list = computed<{ myTeam: MobileListWithSubItem[], teams: MobileListWithSubItem[] }>(() => {
		const accTeamCohort = this.accTeamCohort();

		const data: { myTeam: MobileListWithSubItem[], teams: MobileListWithSubItem[] } = { myTeam: [], teams: [] };

		if (accTeamCohort) {

			data.myTeam = [{
				icon: getIconForIndustry(accTeamCohort.industryId),
				iconStyle: 'fa-regular',
				text: accTeamCohort.business,
				subText: this.util.text.singularOrPluralWithCount(accTeamCohort.countOfTeamMembers, this.labels.TeamMember(), this.labels.TeamMembers()),
				callback: () => {
					this.router.navigate(['my-team'], { relativeTo: this.route });
				}
			}];


			data.teams = accTeamCohort.teams.reduce((a: MobileListWithSubItem[], team) => {
				a.push({
					icon: getIconForIndustry(team.industryId),
					iconStyle: 'fa-regular',
					text: team.business,
					subText: team.entrepreneurs,
				});

				return a;
			}, []);

		}

		return data;

	});

	constructor() {
		this.frame.setUrlMetadata({
			url: `/cohort`,
			backUrl: `/`,
			pageName: computed(() => this.labels.common.Cohort()),
			headerText: computed(() => this.labels.common.Cohort()),
		});
	}

	async ngOnInit() {
		const data = await this.func.cohort.get();
		this.accTeamCohort.set(data);
	}

	getDate(utc: number) {
		return this.util.date.formatUTC(utc, 'MMM D, YYYY', 'No Time', this.urlService.isSpanish() ? 'es-US' : 'en-US');
	}

}