@if(frame.config(); as config)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-info-circle" [text]="labels.ApplicationChanges()" spacing="large"
			iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<div>
			{{labels.ChangeMessage()}}

			@if(applicationService.application(); as application){
				@if(application.canReopen)
				{
				<button class="btn btn-primary" (click)="reopen()">{{labels.Reopen()}}</button>
				}
				@if(application.canWithdraw)
				{
				<button class="btn btn-danger" (click)="withdraw()">{{labels.Withdraw()}}</button>
				}
			}
		</div>


	</mobile-flyout-body-part>
</mobile-flyout-frame>
}