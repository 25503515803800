import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Language: computed(() => en() ? `Language` : `{{ ES: Language }}`),
		
	};
}