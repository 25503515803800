import { Component, ElementRef, computed, input, model, viewChildren } from '@angular/core';
import { NgbDropdownItem, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonIconTextWidget } from '../icon-text/common-icon-text.widget';


export interface CommonSelectorItem<T> {
	id: number | undefined,
	label: string,
	iconStyle: 'fa-regular' | 'fa-solid',
	icon: string,
	data?: T,
}


@Component({
	selector: 'common-selector',
	imports: [NgbDropdownModule, CommonIconTextWidget],
	templateUrl: './common-selector.widget.html',
	styleUrl: './common-selector.widget.scss'
})
export class CommonSelectorWidget {

	public selectLabel = input('Select...');
	public items = input.required<CommonSelectorItem<unknown>[]>();
	public selectedId = model<number | undefined>(undefined);

	private menuItems = viewChildren<ElementRef | undefined>(NgbDropdownItem);

	public readonly selectedItem = computed(() => {
		return this.items().find(item => this.selectedId() == item.id);
	});


	onOpenChange(open: boolean) {
		if (!open) return;

		const selectedId = this.selectedId();
		const items = this.items();
		const index = items.findIndex(item => item.id == selectedId);
		const menuItems = this.menuItems();
		const menuItem = menuItems[index];

		setTimeout(() => {
			menuItem?.nativeElement?.scrollIntoView({ behavior: "instant", block: "nearest" });
		});
	}
}
