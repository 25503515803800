import { inject, Injectable, signal } from "@angular/core";
import { CourseActivity, CourseLevel, CourseVersion, Topic } from "@interfaces";
import { FuncService } from "../../services";
import { UrlService } from "@eforall/common";


@Injectable({ providedIn: 'root' })
export class LearningCourseService {

	private func = inject(FuncService);
	public urlService = inject(UrlService);

	private readonly loaded = signal<boolean>(false);
	private readonly _sessionId = signal<number | undefined>(undefined);
	public readonly sessionId = this._sessionId.asReadonly();

	private readonly _courseVersionId = signal<number | undefined>(undefined);
	public readonly courseVersionId = this._courseVersionId.asReadonly();

	private readonly _course = signal<CourseVersion | undefined>(undefined);
	public readonly course = this._course.asReadonly();

	private readonly _courses = signal<CourseVersion[] | undefined>(undefined);
	public readonly courses = this._courses.asReadonly();

	private readonly _topics = signal<Topic[] | undefined>(undefined);
	public readonly topics = this._topics.asReadonly();


	public async loadCourses() {
		if (!this.loaded()) {
			this.loaded.set(true);

			const data = await this.func.learning.get();
			if (data) {
				if (data.courses?.length) this._courses.set(data.courses);
				else this._topics.set(data.topics);
			}

		}

	}


	public async loadDataAndSetCourseVersion(courseVersionId: number) {

		if (!this._sessionId()) {
			const randomId = this.generateUniqueSessionId();
			this._sessionId.set(randomId);
		}

		await this.loadCourses();

		if (courseVersionId && courseVersionId != this.courseVersionId()) {
			this._courseVersionId.set(courseVersionId);
			const course = this.courses()?.find(course => course.courseVersionId == courseVersionId);
			this._course.set(course);
		}

	}


	private generateUniqueSessionId() {
		return Math.floor(Math.random() * (2 ** 32)); // Random number between 0 and 2^32 - 1 which is INT UNSIGNED value
	}

	public async submitQuiz(courseActivityId: number, courseActivityStructureId: number, selectedChoices: { quizQuestionsId: number, selectedChoiceId: number }[]) {
		const data = await this.func.learning.setQuizActivityResponse({ courseActivityId, courseActivityStructureId, selectedChoices, });
		if (data) this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, completedUTC: data.completedUTC });

		return data;
	}



	public async submitRating(courseActivityId: number, courseActivityStructureId: number, rating: 1 | -1 | undefined) {
		const data = await this.func.learning.setRatingActivityResponse({ courseActivityId, courseActivityStructureId, rating });
		this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, });

		return data;
	}

	public async updateVideo(sessionId: number, courseActivityId: number, courseActivityStructureId: number, latestSecondsWatchedVector: number[]) {
		const data = await this.func.learning.setVideoActivityResponse({ sessionId, courseActivityId, courseActivityStructureId, latestSecondsWatchedVector });
		this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, completedUTC: data.completedUTC });

		return data;
	}


	public async submitWorkbook(courseActivityId: number, courseActivityStructureId: number) {
		const data = await this.func.learning.setWorkbookActivityResponse({ courseActivityId, courseActivityStructureId });
		this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, completedUTC: data.completedUTC });

		return data;
	}

	public async submitPrompt(courseActivityId: number, courseActivityStructureId: number, promptAnswer: string) {
		const data = await this.func.learning.setPromptActivityResponse({ courseActivityId, courseActivityStructureId, promptAnswer });
		this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, completedUTC: data.completedUTC, promptAnswer });

		return data;
	}

	public async submitFeedback(courseActivityId: number, courseActivityStructureId: number, feedbackRating: number, comments?: string) {
		const data = await this.func.learning.setFeedbackActivityResponse({ courseActivityId, courseActivityStructureId, feedbackRating, comments });
		this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, completedUTC: data.completedUTC });

		return data;
	}


	public updateActivityForCourse(data: { courseActivityStructureId: number, courseActivityResponseId: number, completedUTC?: number, promptAnswer?: string, rating?: 1 | -1 }) {
		const course = this.course();

		if (course) {
			const activityForCourseIndex = course.activities.findIndex(
				(activity: CourseActivity) =>
					activity.courseActivityStructureId === data.courseActivityStructureId
			);

			if (activityForCourseIndex !== -1) {

				const activities = [...course.activities];
				// Course activity found
				this.updateActivityResponse(
					activities[activityForCourseIndex]!,
					data.courseActivityResponseId,
					data.completedUTC,
				);

				course.activities = activities;
				this._course.set({ ...course });
				this.replaceCourse({ ...course });
			} else {
				// It's a level activity
				const newCourse = this.findAndUpdateActivity(
					course,
					data.courseActivityStructureId,
					data.courseActivityResponseId,
					data.completedUTC
				);
				this._course.set({ ...newCourse });
				this.replaceCourse({ ...newCourse });

			}
		}
	}


	private replaceCourse(course: CourseVersion) {
		const courses = this.courses();
		if (courses) {
			const courseIndex = courses.findIndex(c => course.courseVersionId == c.courseVersionId);
			courses[courseIndex] = { ...course };

			this._courses.set([...courses]);
		}

	}

	// Helper function to update activity response
	private updateActivityResponse(
		activity: CourseActivity,
		courseActivityResponseId: number,
		completedUTC?: number,
		promptAnswer?: string, rating?: 1 | -1
	) {
		if (activity.response) {
			activity.response = { ...activity.response, completedUTC, promptAnswer, rating };
		} else {
			activity.response = {
				courseActivityResponseId,
				startUTC: Date.now() / 1000,
				rating,
				promptAnswer,
				completedUTC,
			};
		}
	}

	private findAndUpdateActivity(
		course: CourseVersion,
		courseActivityStructureId: number,
		courseActivityResponseId: number,
		completedUTC?: number
	): CourseVersion {
		const newCourse: CourseVersion = { ...course };

		const updateActivityInLevels = (levels: CourseLevel[]): CourseVersion => {
			for (const level of levels) {
				const activityIndex = level.activities.findIndex(
					(activity: CourseActivity) =>
						activity.courseActivityStructureId === courseActivityStructureId
				);

				if (activityIndex !== -1) {
					const activities = [...level.activities];

					this.updateActivityResponse(
						activities[activityIndex]!,
						courseActivityResponseId,
						completedUTC
					);

					level.activities = activities;
					return newCourse; // Return as soon as the activity is found and updated
				}

				// Recursively search in child levels
				if (level.children?.length) {
					const result = updateActivityInLevels(level.children);
					if (result) return result;
				}
			}
			return newCourse;
		};

		return updateActivityInLevels(newCourse.levels);
	}


}