import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Emails: computed(() => en() ? `Emails` : `{{ ES: Emails }}`),
		Language: computed(() => en() ? `Language` : `{{ ES: Language }}`),
		OtherEmails: computed(() => en() ? `Other Emails` : `{{ ES: Other Emails }}`),
		PrimaryEmail: computed(() => en() ? `Primary Email` : `{{ ES: Primary Email }}`),
		MakePrimary: computed(() => en() ? `Make Primary` : `{{ ES: Make Primary }}`),
		AddEmail: computed(() => en() ? `Add an Email` : `{{ ES: Add an Email }}`),
		AddNewEmailMsg: computed(() => en() ? `Sign out and back in with a new email and follow the instructions, or ask a staff member to add an email directly to your account.` : `{{ ES: Sign out and back in with a new email and follow the instructions, or ask a staff member to add an email directly to your account. }}`),
		CanSignInWithAnyEmailMessage: computed(() => en() ? `You can sign in with any of the following emails.` : `{{ ES: You can sign in with any of the following emails. }}`),
		CommunicationSentToPrimaryEmail: computed(() => en() ? `Email communications will always be sent to your primary email.` : `{{ ES: Email communications will always be sent to your primary email. }}`),
		
	};
}