import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, UtilityService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { getLabels } from './home.page.labels';


@Component({
	selector: 'home-page',
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonColumnWidget,
	],
	templateUrl: './home.page.html'
})

export class HomePage {

	private frame = inject(MobileFrameService);
	public util = inject(UtilityService);
	public labels = getLabels(this.frame.urlService);

	public currentDate = computed(() => this.util.date.formatUTC(Date.now() / 1000, 'DOW MMM D, YYYY', 'No Time', this.frame.urlService.isSpanish() ? 'es-US' : 'en-US'));

	constructor() {
		this.frame.setUrlMetadata({
			url: '/',
			backUrl: '/',
			pageName: computed(() => this.labels.common.Home()),
			headerText: computed(() => this.labels.common.Home()),
		});
	}
}