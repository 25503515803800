@if(frame.config(); as config)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-user" text="{{config.user.firstName}} {{config.user.lastName}}"
			spacing="large" iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<common-button-row [center]="true">
			<button class='btn btn-large btn-standard' (click)="toggleLanguage()">
				{{labels.SwitchLanguage()}}
			</button>
		</common-button-row>


		<div class="menu-items">
			@for(menuItem of menu(); track $index;){
			@if(menuItem.routerLink){
			<a [routerLink]="menuItem.routerLink" (click)="close()">
				<i class="{{menuItem.iconStyle}} {{menuItem.icon}} {{menuItem.iconColor}}"></i>
				<span>{{menuItem.label}}</span>
			</a>
			}
			@if(menuItem.url){
			<a [href]="menuItem.url" [target]='menuItem.label' (click)="close()">
				<i class="{{menuItem.iconStyle}} {{menuItem.icon}} {{menuItem.iconColor}}"></i>
				<span>{{menuItem.label}}</span>
			</a>
			}
			}

			<a (click)="signOut()">
				<i class="fa-duotone fa-left-from-bracket"></i>
				<span>{{labels.SignOut()}}</span>
			</a>


			<a routerLink="applications/apply-accelerator" (click)="close()">
				<i class="fa-duotone fa-file-pen"></i>
				<span>Apply Accelerator</span>
			</a>

			<a routerLink="applications/apply-pitch" (click)="close()">
				<i class="fa-duotone fa-file-pen"></i>
				<span>Apply Pitch</span>
			</a>
		</div>
	</mobile-flyout-body-part>
</mobile-flyout-frame>
}