<div class="d-flex flex-column gap-3">
	@if(programDetails(); as programDetails)
	{
	<div class="white-box d-flex flex-column gap-3">
		<h4 class="text-center">{{programDetails.program}}</h4>
		<div>
			<div>{{labels.Deadline()}}: {{programDetails.deadline}}</div>
			<div>{{labels.Language()}}: {{programDetails.programLanguage}}</div>
			<div>{{labels.Contact()}}: <a target='_blank'
					href='mailto:{{programDetails.contact}}'>{{programDetails.contact}}</a>
			</div>
		</div>

		@if(programDetails.hasFeedback){
		<common-button-column>
			<button class="btn btn-primary btn-large"
				(click)="navigateToFeedback()">{{labels.FeedbackAvailable()}}</button>
		</common-button-column>
		}
		@if(applicationService.application()?.canWithdraw || applicationService.application()?.canReopen) {
		<common-button-column>
			<button class="btn btn-primary btn-large"
				(click)="applicationService.openMakeChangesFlyout()">{{labels.MakeChanges()}}</button>
		</common-button-column>
		}
	</div>
	}
	<div class="d-flex flex-column gap-2">
		<h3>{{labels.TellUsAboutYourBusiness()}}</h3>

		@for(data of businessQuestions(); track $index;){
		<div>{{data.question}}: <span class="color-eforall-navy">{{data.answer}}</span></div>
		}

	</div>

	<div class="d-flex flex-column gap-2">
		<h3>{{labels.TellUsAboutYou()}}</h3>
		@for(data of aboutYouQuestions(); track $index;){
		<div>{{data.question}}: <span class="color-eforall-navy">{{data.answer}}</span></div>
		}

	</div>

	<div class="d-flex flex-column gap-2">
		<h3>{{labels.TellUsAboutYourTeam()}}</h3>
		<div>
			<div>{{labels.TeamNameAndBio()}}</div>
			<div class="white-box" style="min-height: 3em; background-color: transparent;">
				{{applicationService.application()?.application?.restOfTeam || 'N/A'}}</div>
		</div>

		@if(applicationService.invitedParticipants().length){
		<table class="table table-md table-bordered align-middle caption-top">
			<caption>{{labels.Participants()}}</caption>
			<tr>
				<td class="p-2">{{applicationService.application()?.applicant?.firstName}}
					{{applicationService.application()?.applicant?.lastName}}</td>
				<td class="p-2">{{applicationService.application()?.applicant?.isCompleted ?labels.Complete() :
					labels.Incomplete()}}</td>
				<td class="p-2"></td>
			</tr>
			@for(invitedParticipant of applicationService.invitedParticipants(); track $index;){
			<tr>
				<td class="p-2">{{invitedParticipant.firstName}} {{invitedParticipant.lastName}}</td>
				<td class="p-2">{{invitedParticipant.status}}</td>
				<td class="p-2">{{invitedParticipant.code?.toUpperCase()}}</td>
			</tr>
			}
		</table>
		}

	</div>

	<div class="d-flex flex-column gap-2">
		<h3>{{labels.ApplicationQuestions()}}</h3>
		@for(data of answersQuestions(); track $index;){
		<div>
			<div>{{data.question}}</div>
			<div class="white-box" style="min-height: 3em; background-color: transparent;">{{data.answer}}</div>
		</div>
		}
	</div>

	@if(applicationService.application()?.canWithdraw || applicationService.application()?.canReopen) {

	<common-button-column>
		<button class="btn btn-primary btn-large" (click)="applicationService.openMakeChangesFlyout()">{{labels.MakeChanges()}}</button>
	</common-button-column>
	}

</div>