import { Component, OnInit, computed, inject, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilityService } from '../../../../services';
import { CommonFieldWidgetBase } from '../common-field-widget-base';
import { CommonPhoneField } from '../field';


@Component({
	selector: 'common-phone-field',
	imports: [FormsModule],
	templateUrl: './common-phone-field.widget.html'
})
export class CommonPhoneFieldWidget extends CommonFieldWidgetBase<string, CommonPhoneField> implements OnInit {

	private util = inject(UtilityService);
	public readonly field = input.required<CommonPhoneField>();


	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: 'Campo requerido',
			minLength: 'El teléfono debe tener al menos 10 dígitos.',
			maxLength: 'El teléfono debe ser más corto que 10 dígitos.',
			notValid: 'Número de teléfono inválido.',
		} : {
			missingRequired: `Required field`,
			minLength: `Phone must be at least 10 digits long.`,
			maxLength: `Phone must be shorter than 10 digits.`,
			notValid: `Invalid phone number`,
		};

	});


	public readonly renderedModel = computed(() => {
		const value = this.field().pendingValue() ?? this.field().actualValue();
		return this.render(value);
	});


	private phonePatternUS = /^(?:\+1)?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;


	ngOnInit() {
		this.field().error.set(this.validate());
	}


	public onChange(value: string) {
		this.field().pendingValue.set(value);
	}


	public async onBlur() {
		await this.blur(this.field());
	}


	protected override cleanValue(value: string): string {
		let n = value.replace(/\D/g, '');
		if (n.length && n[0] == '1') n = n.slice(1);
		if (n.length == 10) return '+1' + n;
		else return '';
	}


	protected override render(value: string) {
		if (this.hasFocus() || this.field().error()) return value;
		return this.util.phone.format(value);
	}


	protected validate(): string {

		const value = (this.getValue(this.field())).trim();

		const config = this.field().config();
		const labels = this.labels();

		if (value.length == 0 && config.required) return labels.missingRequired;
		if (value.length && !value.match(this.phonePatternUS)) return labels.notValid;

		return '';
	}
}