import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Applicant: computed(() => en() ? `Applicant` : `{{ ES: Applicant }}`),
		ApplicantOnly: computed(() => en() ? `Applicant Only` : `{{ ES: Applicant Only }}`),
		ApplicantOtherTeamMembers: computed(() => en() ? `Applicant + Other Team Members` : `{{ ES: Applicant + Other Team Members }}`),
		Instructions: computed(() => en() ? 'Instructions' : '{{ ES : Instructions }}'),
		AddMemberMessage: computed(() => en() ? 'Please list all members of your team that will participate in the program.\n\nIf others will participate besides you, click the Add button for each participant you would like to include. Once you click Add, they will receive an email with an invitation and instructions to enter their information on the application. They must fill out their information in full. You will see Complete next to their name once they have entered all required information. All participants must have a Complete status in order to submit your application. If needed, you can press the green [⋮] button to resend an email to your participant(s) or to remove a participant.' : '{{ ES : Please list all members of your team that will participate in the program.\n\nIf others will participate besides you, click the Add button for each participant you would like to include. Once you click Add, they will receive an email with an invitation and instructions to enter their information on the application. They must fill out their information in full. You will see Complete next to their name once they have entered all required information. All participants must have a Complete status in order to submit your application. If needed, you can press the green [⋮] button to resend an email to your participant(s) or to remove a participant. }}'),
		InstructionMessage: computed(() => en() ? `Please tell us about your current business or business idea. No individual/personal information will be shared with any external third party without your authorization. EforAll will only use aggregated (non-personally identifiable) data for fundraising purposes so our nonprofit can continue to offer our programs at no cost.` : `{{ ES: Please tell us about your current business or business idea. No individual/personal information will be shared with any external third party without your authorization. EforAll will only use aggregated (non-personally identifiable) data for fundraising purposes so our nonprofit can continue to offer our programs at no cost. }}`),
		YourTeam: computed(() => en() ? `Your Team` : `{{ ES: Your Team }}`),
		NextStep: computed(() => en() ? `Next Step` : `{{ ES: Next Step }}`),
		Invite: computed(() => en() ? `Invite` : `{{ ES: Invite }}`),
		Participants: computed(() => en() ? `Participants` : `{{ ES: Participants }}`),
		TeamSize: computed(() => en() ? `Team Size` : `{{ ES: Team Size }}`),
		Another: computed(() => en() ? `Another` : `{{ ES: Another }}`),
		ParticipantDetails: computed(() => en() ? `Participant Details` : `{{ ES: Participant Details }}`),
		InvitedParticipant: computed(() => en() ? `Invited Participant(s)` : `{{ ES: Invited Participant(s) }}`),
		AboutYourBusiness: computed(() => en() ? `About Your Business` : `{{ ES: About Your Business }}`),
		TeamMemberParticipatingQuestion: computed(() => en() ? `Are any of your team members participating fully in this program?` : `{{ ES: Are any of your team members participating fully in this program? }}`),
		MemberNameAndBio: computed(() => en() ? `List name and a short bio of each member of your core team, whether they will participate or not.` : `{{ ES: List name and a short bio of each member of your core team, whether they will participate or not. }}`),
		NoAccessMessage: computed(() => en() ? `Only the applicant can edit business details. TBD` : `{{ ES: Only the applicant can edit business details. TBD }}`),
		FieldsIncompleteMessage: computed(() => en() ? `Please fill out all the required fields in order to send an invite. TBD` : `{{ ES: Please fill out all the required fields in order to send an invite. TBD }}`),
		InviteSuccesfullySentMessage: computed(() => en() ? `Thank you.\n The invite has been successfully sent to {firstName} {lastName} at {email}. TBD` : `{{ ES: Thank you.\n The invite has been successfully sent to {firstName} {lastName} at {email}. TBD }}`),
	}
}