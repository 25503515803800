import { Component, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonIconTextWidget } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService } from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';


@Component({
	selector: 'public-message-flyout-part',
	imports: [
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		RouterModule,
		MarkdownModule,
	],
	providers: [provideMarkdown()],
	templateUrl: './message-flyout.part.html',
	styleUrl: './message-flyout.part.scss'
})
export class PublicMessageFlyoutPart {
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	public frame = inject(MobileFrameService);

	public readonly header = input.required<string>();
	public readonly message = input.required<string>();


	close() {
		this.activeOffcanvas.close();
	}
}