import { Component, computed, inject } from '@angular/core';
import { CommonButtonRowWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';


@Component({
	selector: 'buttons-page',
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
	],
	templateUrl: './buttons.page.html'
})

export class ExampleButtonsPage {
	private frame = inject(MobileFrameService);

	constructor() {
		this.frame.setUrlMetadata({
			url: '/examples/buttons',
			backUrl: '/examples',
			pageName: computed(() => `Buttons`),
			headerText: computed(() => `Buttons`),
		});
	}
}