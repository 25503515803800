import { Injectable, inject, signal } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { PublicFlyoutContainerPart } from './container/flyout-container.part';
import { FlyoutContent } from './flyout-content';
import { CalendarEvent, GoalComment, Participant } from '@interfaces';


@Injectable({ providedIn: 'root' })
export class FlyoutService {

	private offcanvasService = inject(NgbOffcanvas);

	public content = signal<FlyoutContent | undefined>(undefined);

	/**
	 * Shows the message flyout
	 */
	public showUserMenu() {
		this.content.set({
			type: 'UserMenu',
			data: undefined,
		});
		this.openFlyout();
	}


	/**
	 * Shows the message flyout
	 */
	public showMarkdownMessage(message: string, header: string,) {
		this.content.set({
			type: 'Message',
			data: message,
			header: header,
		});
		this.openFlyout();
	}


	public showApplicationReadonly() {
		this.content.set({
			type: 'ApplicationReadonly',
			data: undefined,
		});
		this.openFlyout();
	}

	public showApplicationSubmission() {
		this.content.set({
			type: 'ApplicationSubmission',
			data: undefined,
		});
		this.openFlyout();
	}


	public showApplicationMakeChanges() {
		this.content.set({
			type: 'ApplicationMakeChanges',
			data: undefined,
		});
		this.openFlyout();
	}

	public showApplicationParticipant(participant: Participant) {
		this.content.set({
			type: 'ApplicationParticipant',
			data: participant,
		});
		this.openFlyout();
	}

	public showCalendarEvent(event: CalendarEvent) {
		this.content.set({
			type: 'CalendarEvent',
			data: event,
		});
		this.openFlyout();
	}

	public showGoalComments(comments: GoalComment[]) {
		this.content.set({
			type: 'GoalComments',
			data: comments,
		});
		this.openFlyout();
	}





	private openFlyout() {
		if (!this.offcanvasService.hasOpenOffcanvas()) {

			//
			// The container in which the flyout will be added
			//
			const pageContentElement = document.querySelector('.flyout-container') as HTMLElement;

			if (pageContentElement) {
				this.offcanvasService.open(PublicFlyoutContainerPart, { container: pageContentElement, position: 'end', scroll: false });
			}
		}
	}




	/**
	 * Close any open offcanvas.
	 */
	// close() {
	// 	// this.activeOffcanvas.dismiss();
	// }
}
