import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		BasicInfo: computed(() => en() ? `Basic Info` : `{{ ES: Basic Info }}`),
		Demographics: computed(() => en() ? `Demographics` : `{{ ES: Demographics }}`),
		Emails: computed(() => en() ? `Emails` : `{{ ES: Emails }}`),
		BasicInfoSubtext: computed(() => en() ? `Name, Phone, Address` : `{{ ES: Name, Phone, Address }}`),
		DemographicsSubtext: computed(() => en() ? `Gender, Race, Birthday, etc.` : `{{ ES: Gender, Race, Birthday, etc. }}`),
		SocialLinks: computed(() => en() ? `Social Links` : `{{ ES: Social Links }}`),
		NotificationSettings: computed(() => en() ? `Notification Settings` : `{{ ES: Notification Settings }}`),
		AppLanguage: computed(() => en() ? `App Language` : `{{ ES: App Language }}`),
		
	};
}