import { Component, inject } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MobileScrollableContentWidget } from '../../widgets';
import { MobileAvatarPart } from '../avatar/mobile-avatar.part';
import { MobileFrameService } from '../mobile-frame.service';
import { MobileNavBarPart } from '../nav-bar/mobile-nav-bar.part';
import { MobileToolbarPart } from '../toolbar/mobile-toolbar.part';
import { MobileLayoutService } from './mobile-layout.service';


@Component({
	selector: 'mobile-layout-part',
	imports: [
		MobileNavBarPart,
		MobileScrollableContentWidget,
		NgbTooltipModule,
		MobileAvatarPart,
		MobileToolbarPart,
	],
	templateUrl: './mobile-layout.part.html',
	styleUrl: './mobile-layout.part.scss'
})


export class MobileLayoutPart {

	public layout = inject(MobileLayoutService);
	public frame = inject(MobileFrameService);


	public onPageContentScroll(event: Event) {
		const targetElement = event.target as HTMLElement;
		const scrollPosition = targetElement.scrollTop;
		const scrollHeader = document.querySelector('.header-auto-show');
		if (scrollHeader) {
			if (scrollPosition >= 15) {
				scrollHeader.classList.add('header-active');
			} else scrollHeader.classList.remove('header-active');
		}
	}
}
