import { Component, computed, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, CommonButtonRowWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { ApplicationStatusId } from '@interfaces';
import { AppService } from '../../services';
import { getLabels } from './applications.page.labels';

const OPEN_STATUS = [ApplicationStatusId.ReadPending, ApplicationStatusId.InterviewPending, ApplicationStatusId.SelectPending, ApplicationStatusId.TeamPending];


@Component({
	selector: 'applications-page',
	imports: [
		MobilePagePart,
		CommonButtonColumnWidget,
		CommonButtonRowWidget,
		MobileListWidget,
		RouterLink,
	],
	templateUrl: './applications.page.html'
})

export class ApplicationsPage {
	private frame = inject(MobileFrameService);
	public app = inject(AppService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private util = inject(UtilityService);
	public urlService = inject(UrlService);
	public labels = getLabels(this.urlService);


	public applicationsList = computed<MobileListWithSubItem[]>(() => {
		return this.app.data().userApplications.reduce((a: MobileListWithSubItem[], appl) => {
			a.push({
				icon: 'fa-file-edit',
				iconStyle: 'fa-regular',
				text: `${appl.application.siteName} ${appl.application.programInstance} ${appl.application.programName}`,
				subText: this.getApplicationStatus(appl.application.applicationStatusId),
				callback: () => {
					this.navigateToApplication(appl.application.applicationId)
				}
			});

			return a;
		}, [])
	});

	public readonly applicationInfoContent = computed(() => {
		const applications = this.applicationsList();

		const pendingAppls = applications.filter(appl => appl.subText?.endsWith('Pending'));
		if (pendingAppls.length) return this.util.text.singularOrPluralWithCount(pendingAppls.length, this.labels.PendingApplication(), this.labels.PendingApplications());

		const priorAppls = applications.filter(appl => appl.subText?.endsWith('Completed'));
		if (priorAppls.length) return this.util.text.singularOrPluralWithCount(priorAppls.length, this.labels.PriorApplication(), this.labels.PriorApplications());

		return this.labels.NoApplications();
	});

	constructor() {
		this.frame.setUrlMetadata({
			url: '/applications',
			backUrl: '/',
			pageName: computed(() => this.labels.Applications()),
			headerText: computed(() => this.labels.Applications()),
		});
	}


	public navigateToApplication(applicationId: number) {
		this.router.navigate([applicationId], { relativeTo: this.route });
	}

	private getApplicationStatus(applicationStatusId: ApplicationStatusId) {
		return applicationStatusId == ApplicationStatusId.AcceptPending ? this.labels.Pending() :
			OPEN_STATUS.includes(applicationStatusId) ? this.labels.Open() : this.labels.Completed();
	}
}
