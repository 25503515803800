@if(participant(); as participant)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-info-circle"
			text="{{participant.firstName}} {{participant.lastName}}" spacing="large" iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		@if(!remove() && !copyLink())
		{
		<div>
			<h4>{{labels.InfoMsg().split('{participant-email}').join(participant.email)}}</h4>
			@if(participant.code)
			{
			<div>
				{{labels.InviteCode()}}: {{applicationService.util.text.formatCode(participant.code)}}
			</div>
			}
			<div>
				{{labels.form.Email()}}: {{participant.email}}
			</div>
			<div>
				{{labels.Title()}}: {{participant.title}}
			</div>


		</div>
		<common-button-column>

			<common-button-row [center]="true">
				@if(participant.status === 'Pending'){
				<button class="btn btn-primary" (click)="resendInvite()">{{labels.ResendInvite()}}</button>
				}
				<button class="btn btn-standard" (click)="remove.set(true)">{{labels.button.Remove()}}</button>
			</common-button-row>
			<button class="btn btn-standard" (click)="copyToClipboard()">{{labels.CopyLink()}}</button>

		</common-button-column>
		}
		@else if(remove()) {
		<h4>{{removeInfo()}}</h4>
		<common-button-column>
			<button class="btn btn-danger btn-large" (click)="removeParticipant()">{{labels.button.Yes()}}, {{labels.button.Remove()}}</button>
			<button class="btn btn-standard btn-large" (click)="remove.set(false)">{{labels.button.Cancel()}}</button>
		</common-button-column>
		}
		@else if(copyLink()){
		<h4>{{labels.LinkCopiedToClipboard()}} {{participant.firstName}} {{participant.lastName}}</h4>
		<common-button-column>
			<button class="btn btn-standard btn-large" (click)="copyLink.set(false)">{{labels.button.Okay()}}</button>
		</common-button-column>
		}
	</mobile-flyout-body-part>
</mobile-flyout-frame>
}