import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,
		...urlService.commonLabels.button,

		Answer: computed(() => en() ? 'Answer (select one)' : 'Respuesta (seleccione una)'),
		PitchContests: computed(() => en() ? `Pitch Contests` : 'Concursos Lanza Tu Idea'),
		BusinessAccelerator: computed(() => en() ? `Business Accelerator` : `Acelerador de Negocios`),
		PicBlob: computed(() => en() ? `Fast-paced, fun community events that help early-stage entrepreneurs gain valuable exposure and feedback on a business idea. Great opportunity to network, validate a business idea, and compete to win a cash prize. It is free for entrepreneurs to apply and free for all to attend.` : `Eventos para la comunidad vertiginosos y divertidos que ayudan a los emprendedores en las etapas iniciales a obtener exposición valiosa y comentarios sobre una idea de negocios. Excelente oportunidad para crear una red empresarial, validar una idea de negocios y competir para ganar un premio en dinero. Los emprendedores pueden postularse sin costa alguno y todos pueden asistir gratis.`),
		AccBlob: computed(() => en() ? `The EforAll Accelerator Program is a free, 12-week program offered twice a year in each of our communities. This program offers a unique combination of immersive business training, mentorship, and access to an extended professional network. Anyone with a dream to start or grow a business or nonprofit is encouraged to apply.` : `El Acelerador de Negocios de EparaTodos es un programa gratuito de doce semanas que se ofrece dos veces por año en cada una de nuestras comunidades. Este programa ofrece una combinación única de capacitación y mentoría inmersivas, y acceso a una amplia red profesional. Cualquiera que sueñe con iniciar o desarrollar un negocio u organización sin fines de lucro puede postular.`),

	};
}