import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		
		Complete: computed(() => en() ? `Complete` : `Completado`),
		Completed: computed(() => en() ? `Completed` : `Completado`),
		Course: computed(() => en() ? `Course` : `Curso`),
		CourseOverview: computed(() => en() ? `Course Overview` : `Descripción del Curso`),
		Feedback: computed(() => en() ? `Feedback` : `Retroalimentación`),
		Incomplete: computed(() => en() ? `Incomplete` : `Incompleto`),
		Module: computed(() => en() ? `Module` : `Módulo`),
		NoActivities: computed(() => en() ? `No Activities` : `No hay actividades`),
		NoActivitiesAvailable: computed(() => en() ? `No Activities Available` : `No hay actividades disponibles`),
		NotEnrolledMsg: computed(() => en() ? `You are not enrolled in this course` : `No estás inscrito en este curso.`),
		NotStarted: computed(() => en() ? `Not started` : `No ha comenzado`),
		Of: computed(() => en() ? `of` : `de`),
		Optional: computed(() => en() ? `Optional` : `Opcional`),
		Prompt: computed(() => en() ? `Prompt` : `Reflexiona`),
		Quiz: computed(() => en() ? `Quiz` : `Quiz`),
		RequiredActivitiesComplete: computed(() => en() ? `Required Activities Complete` : `actividades requeridas completadas`),
		AllOptionalActivities: computed(() => en() ? `All activities are optional` : `Todas las actividades so opcionales`),
		Resume: computed(() => en() ? `Resume` : `Reanudar`),
		Section: computed(() => en() ? `Section` : `Sección`),
		Started: computed(() => en() ? `Started` : `Comenzado`),
		Topic: computed(() => en() ? `Topic` : `Tema`),
		UpdatedBy: computed(() => en() ? `Updated By` : `Actualizado por`),
		Video: computed(() => en() ? `Video` : `Video`),
		Workbook: computed(() => en() ? `Workbook` : `Cuaderno de Actividades`),
	};
}