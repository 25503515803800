import { Component, computed, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonSelectFieldWidget, CommonTextFieldWidget, UrlService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { CohortBusinessFormService } from './business-form.service';
import { getLabels } from './business.page.labels';


@Component({
	selector: 'business-page',
	imports: [
		MobilePagePart,
		CommonTextFieldWidget,
		CommonSelectFieldWidget,
		FormsModule,
	],
	templateUrl: './business.page.html'
})

export class MyTeamBusinessPage implements OnInit {

	private frame = inject(MobileFrameService);
	public form = inject(CohortBusinessFormService);
	private urlService = inject(UrlService);
	private labels = getLabels(this.urlService);


	constructor() {
		this.frame.setUrlMetadata({
			url: `/cohort/my-team/business-details`,
			backUrl: `/cohort/my-team`,
			pageName: computed(() => this.labels.BusinessDetails()),
			headerText: computed(() => this.labels.BusinessDetails()),
		});
	}


	async ngOnInit() {
		await this.form.loadData();
	}

}