import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Comments: computed(() => en() ? 'Comments' : 'Comentarios'),
		AdditionalComments: computed(() => en() ? 'Additional Comments' : 'Comentarios adicionales'),
		NoComments: computed(() => en() ? 'No comments' : 'No hay comentarios'),
	};
}