import { AngularEnvironment } from './angular-environment';

export const environment: AngularEnvironment = {
	name: 'DEV',
	appName: 'Public',
	baseUrl: 'https://app-dev.eforall.org/',
	bodyCssClasses: ['navy-swoop'],
	frame: 'Mobile',
	authType: 'Internal',
	supportsSpanish: true,
	functionUrl: "https://us-central1-eforall-public-dev.cloudfunctions.net/",
	firebaseConfig: {
		apiKey: "AIzaSyBmRqLr0BzsxViVQFxyK0o7W7vaZ8WaFrA",
		authDomain: "eforall-public-dev.firebaseapp.com",
		projectId: "eforall-public-dev",
		storageBucket: "eforall-public-dev.appspot.com",
		messagingSenderId: "1053298937748",
		appId: "1:1053298937748:web:3b0f40d9600b14fffb4981",
		measurementId: "G-8XFE09GS8W"
	},
};
