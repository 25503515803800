import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonButtonRowWidget, CommonMessageWidget, UtilityService, WistiaService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { CourseActivity, CourseActivityFeedback, CourseActivityPrompt, CourseActivityResponseType, CourseActivityResponseTypeFeedback, CourseActivityResponseTypePrompt, CourseActivityResponseTypeQuiz, CourseActivityResponseTypeVideo, CourseActivityResponseTypeWorkbook, CourseActivityTypeId, CourseActivityWorkbook, CourseLevel, CourseVersion } from '@interfaces';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { FlyoutService, FuncService } from '../../../services';
import { LearningCourseService } from '../course.service';
import { getLabels } from './activity.page.labels';
import { LearningFeedbackPart } from './feedback/feedback.part';
import { LearningPromptPart } from './prompt/prompt.part';
import { LearningQuizPart } from './quiz/quiz.part';
import { LearningVideoPart } from './video/video.part';
import { LearningWorkbookPart } from './workbook/workbook.part';

const ONE_WEEK_IN_SECONDS = 7 * 24 * 60 * 60;


interface ActivityDetails {
	courseVersionId: number,
	courseActivityId: number,
	deadline?: number,
	parents: string,
	header: string,
	title: string,
	optional: boolean,
	description?: string,
	type: 'Feedback' | 'Prompt' | 'Quiz' | 'Video' | 'Workbook',
	data?: CourseActivityResponseType,
}


@Component({
	selector: 'activity-page',
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
		RouterLink,
		CommonMessageWidget,
		MarkdownModule,
		LearningVideoPart,
		LearningFeedbackPart,
		LearningWorkbookPart,
		LearningPromptPart,
		LearningQuizPart,
	],
	providers: [provideMarkdown()],
	templateUrl: './activity.page.html'
})

export class LearningActivityPage implements OnInit {
	private frame = inject(MobileFrameService);
	private route = inject(ActivatedRoute);
	private func = inject(FuncService);
	private util = inject(UtilityService);
	private router = inject(Router);
	public flyoutService = inject(FlyoutService);
	public labels = getLabels(this.frame.urlService);
	private wistiaService = inject(WistiaService);
	public courseService = inject(LearningCourseService);



	private readonly _courseActivityStructureId = signal<number | undefined>(undefined);
	public readonly courseActivityStructureId = this._courseActivityStructureId.asReadonly();


	public readonly activityNotAvailable = computed<{ message: string, courseActivityStructureId?: number } | undefined>(() => {
		const course = this.courseService.course();
		const courseActivityId = this.courseActivityStructureId();

		if (course && courseActivityId) {
			const result = this.hasIncompleteParentActivitiesOrAvailability(course, courseActivityId);
			if (result.incomplete) {
				return {
					message: this.labels.CompletePriorRequiredActivitiesMsg() + ' ' + result.activityTitle,
					courseActivityStructureId: result.activityId,
				}
			}
			if (result.unavailableMessage) {
				return { message: result.unavailableMessage };
			}
		}

		return undefined;
	});

	public readonly activityDetails = signal<ActivityDetails | undefined>(undefined);


	public readonly cannotMoveForwardMsg = computed<string | undefined>(() => {
		const activityDetails = this.activityDetails();
		if (activityDetails) {

			if (activityDetails.optional) return undefined;
			else if (activityDetails.data?.type == 'Feedback' && !this.feedbackResponse()) return this.labels.ProvideFeedbackBeforeNextActivity();
			else if (activityDetails.data?.type == 'Prompt' && !this.promptAnswer()) return this.labels.ProvidePromptBeforeNextActivity();
			else if (activityDetails.data?.type == 'Workbook' && !this.workbookCompleted()) return this.labels.ProvideWorkbookBeforeNextActivity();
			else if (activityDetails.data?.type == 'Video' && !activityDetails.data?.data.completedUTC) return this.labels.ProvideVideoBeforeNextActivity() + (activityDetails.data.data.furthestWatchedSecond ? ' ' + this.labels.LastLeftOffAtVideo().split('{minutes}').join(`${this.util.date.secondsToMinutes(activityDetails.data.data.furthestWatchedSecond!)}`) : '');
			else if (activityDetails.data?.type == 'Quiz' && !activityDetails.data?.data.completedUTC) return this.labels.CompleteCurrentActivityBeforeMsg();
		}

		return undefined;

	});

	public readonly promptAnswer = signal<string | undefined>(undefined);
	public readonly workbookCompleted = signal<boolean | undefined>(false);
	public readonly feedbackResponse = signal<{
		feedbackRating: number,
		comments?: string
	} | undefined>(undefined)
	public nextActivityUrl = signal<string | undefined>(undefined);
	public previousActivityUrl = signal<string | undefined>(undefined);


	async ngOnInit() {
		const courseVersionId = +this.route.snapshot.params['courseVersionId'];
		const courseActivityStructureId = +this.route.snapshot.params['courseActivityStructureId'];
		this._courseActivityStructureId.set(courseActivityStructureId);

		this.frame.setUrlMetadata({
			url: `/learning/courses/${courseVersionId}/activity/${courseActivityStructureId}`,
			backUrl: `/learning/courses/${courseVersionId}`,
			pageName: computed(() => this.labels.Activity()),
			headerText: computed(() => this.labels.Activity()),
		});

		await this.courseService.loadDataAndSetCourseVersion(courseVersionId);

		const course = this.courseService.course();

		if (course && !this.activityNotAvailable()) {
			await this.processCourseActivities(course, courseActivityStructureId, courseVersionId);
		} else {
			this.activityDetails.set(undefined);
		}

	}


	private hasIncompleteParentActivitiesOrAvailability(
		course: CourseVersion,
		targetActivityId: number
	): { incomplete: boolean; activityId?: number; activityTitle?: string; unavailableMessage?: string } {
		const nowUTC = Date.now() / 1000;

		// Helper function to find incomplete activity
		const findIncompleteActivity = (activities: CourseActivity[]): { incomplete: boolean; activityId?: number; activityTitle?: string } | null => {
			for (const activity of activities) {
				if (!activity.activityOptional && (!activity.response || !activity.response.completedUTC)) {
					return {
						incomplete: true,
						activityId: activity.courseActivityStructureId,
						activityTitle: activity.activityTitle,
					};
				}
			}
			return null;
		};

		// Recursive function to collect all prior activities
		const collectPriorActivities = (
			level: CourseLevel,
			collectedActivities: CourseActivity[]
		): CourseActivity[] => {
			const allActivities = [...collectedActivities, ...level.activities];
			for (const childLevel of level.children) {
				allActivities.push(...collectPriorActivities(childLevel, allActivities));
			}
			return allActivities;
		};

		// Recursive function to check levels for availability or incompleteness
		const findInLevel = (
			level: CourseLevel,
			collectedActivities: CourseActivity[]
		): { incomplete: boolean; activityId?: number; activityTitle?: string; unavailableMessage?: string } | null => {
			// Collect all activities up to this level
			const allActivities = [...collectedActivities, ...level.activities];

			// Check for the target activity in the current level
			const targetActivityIndexInLevel = level.activities.findIndex(
				(activity) => activity.courseActivityStructureId === targetActivityId
			);
			if (targetActivityIndexInLevel !== -1) {
				// If found, check all prior activities
				const priorActivities = allActivities.slice(0, allActivities.length - (level.activities.length - targetActivityIndexInLevel));
				const incomplete = findIncompleteActivity(priorActivities);
				if (incomplete) return incomplete;
			}

			// Check if the level has a deadline and the target activity is not yet available
			if (level.deadline) {
				const oneWeekBeforeDeadlineUTC = level.deadline - ONE_WEEK_IN_SECONDS;
				if (nowUTC < oneWeekBeforeDeadlineUTC) {
					const activityExistsInLevel = level.activities.some(
						(activity) => activity.courseActivityStructureId === targetActivityId
					);
					if (activityExistsInLevel) {
						return {
							incomplete: false,
							unavailableMessage: `${this.labels.ActivityNotAvailableYetMsg()} ${this.util.date.formatUTC(
								oneWeekBeforeDeadlineUTC,
								'MMM D, YYYY',
								'No Time',
								this.courseService.urlService.isSpanish() ? 'es-US' : 'en-US'
							)} `,
						};
					}
				}
			}

			// Recursively check child levels
			for (const childLevel of level.children) {
				const result = findInLevel(childLevel, allActivities);
				if (result) return result;
			}

			return null;
		};

		// First, check top-level course activities
		const targetActivityIndex = course.activities.findIndex(
			(activity) => activity.courseActivityStructureId === targetActivityId
		);
		if (targetActivityIndex !== -1) {
			// If target is in top-level activities, check all prior activities
			const priorActivities = course.activities.slice(0, targetActivityIndex);
			const incomplete = findIncompleteActivity(priorActivities);
			if (incomplete) return incomplete;
		}

		// Then, traverse through levels recursively
		for (const level of course.levels) {
			const result = findInLevel(level, [...course.activities]);
			if (result) return result;
		}

		// If no issues are found
		return { incomplete: false };
	}




	private async processCourseActivities(course: CourseVersion, courseActivityStructureId: number, courseVersionId: number) {
		const activityForCourseIndex = course.activities.findIndex((activity: CourseActivity) =>
			activity.courseActivityStructureId === courseActivityStructureId
		);

		//
		// if it is a course activity, i.e the activity is not attached to level but the course itself
		//
		if (activityForCourseIndex !== -1) {
			await this.handleCourseVersionActivity(course.activities[activityForCourseIndex]!, course.activities, activityForCourseIndex, courseVersionId);
		} else {
			const { level, activity, parents, activityIndex, levelIndex } = this.findActivityAndIndex(course.levels, courseActivityStructureId);
			if (activity && level) {

				this.frame.setUrlMetadata({
					url: `/learning/courses/${courseVersionId}/activity/${courseActivityStructureId}`,
					backUrl: `/learning/courses/${courseVersionId}/level/${level.courseLevelStructureId}`,
					pageName: computed(() => this.labels.Activity()),
					headerText: computed(() => this.labels.Activity()),
				});
				await this.setActivityDetails(activity, level, parents, activityIndex, levelIndex, courseVersionId);
				this.setNextActivityUrl(level.activities, activityIndex);
				this.setPreviousActivityUrl(level.activities, activityIndex);

			}
		}
	}


	private setNextActivityUrl(activities: CourseActivity[], index: number) {
		this.nextActivityUrl.set(index + 1 === activities.length ? `../..` : `../${activities[index + 1]!.courseActivityStructureId}`);
	}

	private setPreviousActivityUrl(activities: CourseActivity[], index: number) {
		// Check if there is a previous activity (index > 0)
		this.previousActivityUrl.set(index > 0 ? `../${activities[index - 1]!.courseActivityStructureId}` : `../..`);
	}

	private async setActivityDetails(activity: CourseActivity, level: CourseLevel, parents: string, activityIndex: number, levelIndex: number, courseVersionId: number) {
		const data = await this.getActivityFromDb(activity);
		const levelType = this.labels[<'Module' | 'Section' | 'Topic'>level.levelType]();

		this.activityDetails.set({
			courseVersionId,
			courseActivityId: activity.courseActivityId,
			parents,
			deadline: level.deadline,
			optional: activity.activityOptional,
			header: `${levelType} ${levelIndex}: ${level.levelTitle}`,
			title: `${activity.activityOptional ? this.labels.OptionalActivity() : this.labels.Activity()} ${activityIndex + 1}: ${activity.activityTitle}`,
			description: activity.activityDescription,
			type: activity.courseActivityType as 'Feedback' | 'Prompt' | 'Quiz' | 'Video' | 'Workbook',
			data,
		});

	}



	private async handleCourseVersionActivity(activity: CourseActivity, activities: CourseActivity[], index: number, courseVersionId: number) {
		const data = await this.getActivityFromDb(activity);
		const activityDetails: ActivityDetails = {
			courseVersionId,
			courseActivityId: activity.courseActivityId,
			parents: activity.activityTitle,
			deadline: undefined,
			title: '',
			optional: activity.activityOptional,
			header: `${this.labels.Activity()} ${index + 1}: ${activity.activityTitle}`,
			description: activity.activityDescription,
			type: activity.courseActivityType as 'Feedback' | 'Prompt' | 'Quiz' | 'Video' | 'Workbook',
			data,
		};
		this.activityDetails.set(activityDetails);
		this.setNextActivityUrl(activities, index);
		this.setPreviousActivityUrl(activities, index);

	}

	private findActivityAndIndex(levels: CourseLevel[], courseActivityStructureId: number): {
		level: CourseLevel | undefined,
		activity: CourseActivity | undefined,
		parents: string,
		levelIndex: number,
		activityIndex: number,
	} {
		const findInLevel = (level: CourseLevel, currentParents: string[], levelIndex: number): {
			level: CourseLevel | undefined,
			activity: CourseActivity | undefined,
			parents: string,
			levelIndex: number,
			activityIndex: number,
		} => {
			for (let i = 0; i < level.activities.length; i++) {
				const activity = level.activities[i]!;
				if (activity.courseActivityStructureId === courseActivityStructureId) {
					return {
						level,
						activity,
						parents: [...currentParents].join(' > '),
						levelIndex,
						activityIndex: i,
					};
				}
			}
			for (let i = 0; i < level.children.length; i++) {
				const childLevel = level.children[i]!;
				const childLevelType = this.labels[<'Module' | 'Section' | 'Topic'>childLevel.levelType]();
				const result = findInLevel(childLevel, [...currentParents, `${childLevelType} ${i + 1}`], i + 1);
				if (result.level) return result;
			}
			return { level: undefined, activity: undefined, parents: '', levelIndex: -1, activityIndex: -1 };
		};

		for (let i = 0; i < levels.length; i++) {
			const level = levels[i]!;
			const levelType = this.labels[<'Module' | 'Section' | 'Topic'>level.levelType]();
			const result = findInLevel(level, [`${levelType} ${i + 1}`], i + 1);
			if (result.level) return result;
		}
		return { level: undefined, activity: undefined, parents: '', levelIndex: -1, activityIndex: -1 };
	}


	private async getActivityFromDb(activity: CourseActivity): Promise<CourseActivityResponseType | undefined> {

		if (activity.courseActivityTypeId == CourseActivityTypeId.Video && activity.courseVideoActivityId) {
			const sessionId = this.courseService.sessionId();
			if (sessionId) {
				const videoActivity = await this.func.learning.getVideoActivityResponse({
					sessionId,
					courseActivityId: activity.courseActivityId,
					courseActivityStructureId: activity.courseActivityStructureId
				});

				this.courseService.updateActivityForCourse({ courseActivityStructureId: activity.courseActivityStructureId, courseActivityResponseId: videoActivity.courseActivityResponseId, completedUTC: videoActivity.completedUTC });

				videoActivity.ratingQuestion = this.labels.WasThisContentHelpful();

				return {
					type: 'Video',
					data: videoActivity,
				}
			}
		}

		if (activity.courseActivityTypeId == CourseActivityTypeId.Workbook && activity.courseWorkbookActivityId) {

			const workbookActivity = await this.func.learning.getWorkbookActivityResponse({
				courseActivityId: activity.courseActivityId,
				courseActivityStructureId: activity.courseActivityStructureId
			});

			this.courseService.updateActivityForCourse({ courseActivityStructureId: activity.courseActivityStructureId, courseActivityResponseId: workbookActivity.courseActivityResponseId, completedUTC: workbookActivity.completedUTC });

			return {
				type: 'Workbook',
				data: {
					courseWorkbookActivityId: activity.courseWorkbookActivityId,
					infoText: workbookActivity.infoText,
					completedUTC: workbookActivity.completedUTC,
					courseActivityResponseId: workbookActivity.courseActivityResponseId
				}
			}

		}

		if (activity.courseActivityTypeId == CourseActivityTypeId.Prompt && activity.coursePromptActivityId) {

			const promptActivity = await this.func.learning.getPromptActivityResponse({
				courseActivityId: activity.courseActivityId,
				courseActivityStructureId: activity.courseActivityStructureId
			});

			this.courseService.updateActivityForCourse({ courseActivityStructureId: activity.courseActivityStructureId, courseActivityResponseId: promptActivity.courseActivityResponseId, completedUTC: promptActivity.completedUTC });

			return {
				type: 'Prompt',
				data: promptActivity,
			}

		}

		if (activity.courseActivityTypeId == CourseActivityTypeId.Feedback) {

			const feedbackActivity = await this.func.learning.getFeedbackActivityResponse({
				courseActivityId: activity.courseActivityId,
				courseActivityStructureId: activity.courseActivityStructureId
			});

			this.courseService.updateActivityForCourse({ courseActivityStructureId: activity.courseActivityStructureId, courseActivityResponseId: feedbackActivity.courseActivityResponseId, completedUTC: feedbackActivity.completedUTC });

			return {
				type: 'Feedback',
				data: feedbackActivity,
			}


		}
		if (activity.courseActivityTypeId == CourseActivityTypeId.Quiz && activity.courseQuizActivityId) {

			const quizActivity = await this.func.learning.getQuizActivityResponse({
				courseActivityId: activity.courseActivityId,
				courseActivityStructureId: activity.courseActivityStructureId
			});

			this.courseService.updateActivityForCourse({ courseActivityStructureId: activity.courseActivityStructureId, courseActivityResponseId: quizActivity.courseActivityResponseId, completedUTC: quizActivity.completedUTC });

			return {
				type: 'Quiz',
				data: quizActivity,
			}

		}

		return undefined;

	}




	public async submitRating(rating: 1 | -1 | undefined) {
		const activityDetails = this.activityDetails();

		//
		// Currently for video only
		//
		if (activityDetails && activityDetails.data && activityDetails.data.type == 'Video') {
			const courseActivityResponseTypeVideoRating = activityDetails.data.data;
			const result = await this.courseService.submitRating(activityDetails.courseActivityId, this.courseActivityStructureId()!, rating);

			const newData: CourseActivityResponseTypeVideo = {
				type: 'Video',
				data: {
					...courseActivityResponseTypeVideoRating,
					videoRating: result.rating,
				},
			};


			this.activityDetails.set({
				...activityDetails,
				data: { ...newData },
			});

		}

	}



	public async submitQuiz(selectedChoices: {
		quizQuestionsId: number,
		selectedChoiceId: number,
	}[]) {
		const activityDetails = this.activityDetails();

		if (activityDetails && activityDetails.data && activityDetails.data.type == 'Quiz') {
			const courseActivityResponseTypeQuiz = activityDetails.data.data;
			const result = await this.courseService.submitQuiz(activityDetails.courseActivityId, this.courseActivityStructureId()!, selectedChoices);

			if (result) {
				const selectedChoicesMap = this.util.array.toMap(selectedChoices, choice => choice.quizQuestionsId);


				const newData: CourseActivityResponseTypeQuiz = {
					type: 'Quiz',
					data: {
						...courseActivityResponseTypeQuiz,
						countOfAttempts: result.countOfAttempts,
						courseActivityQuizResponseId: result.courseActivityQuizResponseId,
						attemptSubmittedUTC: result.attemptSubmittedUTC,
						percentCorrect: result.percentCorrect,
						completedUTC: result.completedUTC,
						questions: [...courseActivityResponseTypeQuiz.questions].map(question => {
							question.selectedChoiceId = selectedChoicesMap[question.quizQuestionsId]!.selectedChoiceId;
							return question;
						}),
					},
				};


				this.activityDetails.set({
					...activityDetails,
					data: { ...newData },
				});
			}

		}

	}


	public async addAnotherQuizAttempt() {
		const activityDetails = this.activityDetails();

		if (activityDetails && activityDetails.data && activityDetails.data.type == 'Quiz') {
			const courseActivityResponseTypeQuiz = activityDetails.data.data;

			const newData: CourseActivityResponseTypeQuiz = {
				type: 'Quiz',
				data: {
					...courseActivityResponseTypeQuiz,
					courseActivityQuizResponseId: undefined,
					attemptSubmittedUTC: undefined,
					percentCorrect: undefined,
					questions: [...courseActivityResponseTypeQuiz.questions].map(question => {
						question.selectedChoiceId = undefined;
						return question;
					}),
				},
			};


			this.activityDetails.set({
				...activityDetails,
				data: { ...newData },
			});

		}

	}




	public async updateVideo(vector: number[]) {

		const activityDetails = this.activityDetails();

		if (activityDetails && activityDetails.data && activityDetails.data.type == 'Video') {
			const courseActivityResponseTypeVideo = activityDetails.data.data;

			if (this.util.values.areSame(courseActivityResponseTypeVideo.responseSessionWatchedVector, vector)) return;

			const hashedId = await this.wistiaService.getHashedId();

			if (courseActivityResponseTypeVideo.wistiaId != hashedId || !this.wistiaService.isPlaying()) return;

			const result = await this.courseService.updateVideo(this.courseService.sessionId()!, activityDetails.courseActivityId, this.courseActivityStructureId()!, vector);

			const newData: CourseActivityResponseTypeVideo = {
				type: 'Video',
				data: {
					...courseActivityResponseTypeVideo,
					percentWatched: result.percentWatched ?? 0,
					completedUTC: result.completedUTC ?? undefined,
				},
			};


			this.activityDetails.set({
				...activityDetails,
				data: { ...newData },
			});

		}

	}




	private async submitWorkbook(courseActivityId: number, workBookData: CourseActivityWorkbook) {

		const courseActivityResponseTypeWorkbook = workBookData;

		const result = await this.courseService.submitWorkbook(courseActivityId, this.courseActivityStructureId()!);
		courseActivityResponseTypeWorkbook.completedUTC = result.completedUTC;

		const newData: CourseActivityResponseTypeWorkbook = {
			type: 'Workbook',
			data: {
				...courseActivityResponseTypeWorkbook,
			},
		};

		return newData;

	}


	private async submitPrompt(courseActivityId: number, promptData: CourseActivityPrompt, promptAnswer: string) {

		const courseActivityResponseTypePrompt = promptData;

		const result = await this.courseService.submitPrompt(courseActivityId, this.courseActivityStructureId()!, promptAnswer);
		courseActivityResponseTypePrompt.promptAnswer = result.promptAnswer;
		courseActivityResponseTypePrompt.completedUTC = result.completedUTC;

		const newData: CourseActivityResponseTypePrompt = {
			type: 'Prompt',
			data: {
				...courseActivityResponseTypePrompt,
			},
		};

		return newData;

	}


	private async submitFeedback(courseActivityId: number, feedbackData: CourseActivityFeedback, feedbackRating: number, comments?: string) {

		const courseActivityResponseTypeFeedback = feedbackData;

		const result = await this.courseService.submitFeedback(courseActivityId, this.courseActivityStructureId()!, feedbackRating, comments);
		courseActivityResponseTypeFeedback.feedbackRating = feedbackRating;
		courseActivityResponseTypeFeedback.comments = comments;

		const newData: CourseActivityResponseTypeFeedback = {
			type: 'Feedback',
			data: {
				...courseActivityResponseTypeFeedback,
			},
		};

		return newData;

	}


	public setWorkbookCompleted() {
		this.workbookCompleted.set(true);
	}

	public setPromptAnswer(answer: string) {
		this.promptAnswer.set(answer);
	}

	public setFeedback(response: { feedbackRating: number, comments?: string, }) {
		this.feedbackResponse.set(response);
	}

	public async onNextClick() {
		const cannotMoveForwardMsg = this.cannotMoveForwardMsg();
		if (cannotMoveForwardMsg) {
			this.flyoutService.showMarkdownMessage(cannotMoveForwardMsg, this.labels.Message());
		}
		else {

			const activityDetails = this.activityDetails();

			if (activityDetails && activityDetails.data && activityDetails.data.type == 'Workbook') {
				const newData = await this.submitWorkbook(activityDetails.courseActivityId, activityDetails.data.data);

				this.activityDetails.set({
					...activityDetails,
					data: { ...newData },
				});
			}

			const promptAnswer = this.promptAnswer();
			if (activityDetails && activityDetails.data && activityDetails.data.type == 'Prompt' && promptAnswer) {
				const newData = await this.submitPrompt(activityDetails.courseActivityId, activityDetails.data.data, promptAnswer);

				this.activityDetails.set({
					...activityDetails,
					data: { ...newData },
				});
			}


			const feedbackResponse = this.feedbackResponse();
			if (activityDetails && activityDetails.data && activityDetails.data.type == 'Feedback' && feedbackResponse) {
				const newData = await this.submitFeedback(activityDetails.courseActivityId, activityDetails.data.data, feedbackResponse.feedbackRating, feedbackResponse.comments);

				this.activityDetails.set({
					...activityDetails,
					data: { ...newData },
				});
			}

			this.router.navigate([this.nextActivityUrl()], { relativeTo: this.route });
		}


	}





}