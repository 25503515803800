import { Component, computed, inject } from '@angular/core';
import { CommonButtonRowWidget, UrlService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { getLabels } from './programs.page.labels';


@Component({
	selector: 'programs-page',
	imports: [
		MobilePagePart,
		CommonButtonRowWidget
	],
	templateUrl: './programs.page.html'
})

export class ProgramsPage {
	private frame = inject(MobileFrameService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);


	public readonly programsList = computed(() => {
		const isSpanish = this.urlService.isSpanish();
		return [
			{
				header: this.labels.BusinessAccelerator(),
				blob: this.labels.AccBlob(),
				url: isSpanish ? 'https://eforall.org/es/acelerador-de-negocios/' : 'https://eforall.org/business-accelerator-program/',
			},
			{
				header: this.labels.PitchContests(),
				blob: this.labels.PicBlob(),
				url: isSpanish ? 'https://eforall.org/es/lanza-tu-idea/' : 'https://eforall.org/pitch-contests/',
			},
		]
	});

	constructor() {
		this.frame.setUrlMetadata({
			url: `/programs`,
			backUrl: `/`,
			pageName: computed(() => this.labels.common.Programs()),
			headerText: computed(() => this.labels.common.Programs()),
		});
	}
}