import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		BusinessDetails: computed(() => en() ? `Business Details` : `{{ ES: Business Details }}`),
	};
}