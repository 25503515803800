
import { User } from 'firebase/auth';
import firebase from 'firebase/compat/app';


export enum AuthError {

	/** The FirebaseUI library requires the browser to support web storage */
	WebStorageNotSupported,

	/** Firebase Auth raised an error */
	FirebaseAuthError,

	/** firebase.User.emailVerified is false */
	EmailNotVerified,
}


/**
 * Auth status when an error occurred or if auth is not available
 */
export interface ErrorAuthState {

	status: 'error';
	error: AuthError;

	/** The error reported from Firebase if the error is FirebaseAuthError */
	firebaseAuthError?: firebase.auth.Error,
}


/**
 * Auth status when the service is in an unknown state, while logging in.
 */
export interface PendingAuthState {
	status: 'pending'
}


/**
 * Auth status when the service is in a known state of being signed out
 */
export interface SignedOutAuthState {
	status: 'signed-out'
}


/**
 * Auth status when the service is in a known state of being signed in
 */
export interface SignedInAuthState {
	status: 'signed-in';
	user: User;
	loginType: 'new' | 'from-persistence';
}


/**
 * A discriminated union of possible auth statuses. Access status-specific values by
 * comparing the status property to 'error', 'logged-out', or 'logged-in'
 */
export type AuthState = PendingAuthState | ErrorAuthState | SignedOutAuthState | SignedInAuthState;
