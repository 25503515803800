import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		// Example: computed(() => en() ? `Example` : `{{ ES: Example }}`),

	};
}