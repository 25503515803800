@if(quiz(); as quiz){
<div class="d-flex flex-column gap-4">

	@if(quiz.attemptSubmittedUTC){
	<common-message>
		<div>
			<div>{{ labels.PercentCorrectMsg().split('{percentCorrect}').join('' + quiz.percentCorrect!) }}</div>
			<div>{{labels.Attempt()}} {{quiz.countOfAttempts}} {{labels.Of()}} 3.</div>
		</div>
	</common-message>
	}

	@for(question of questions(); track $index; let idx = $index){
	<div>
		<h4>
			{{urlService.commonLabels.form.Question()}} {{idx+ 1}}: {{question.label}}
		</h4>

		<div class="d-flex flex-column gap-2">
			@if(quiz.attemptSubmittedUTC){
			<common-message [alertType]="question.correctChoiceId !== question.field.actualValue() ? 'error':'success'">
				<div class="d-flex flex-row justify-content-between">
					@if(question.correctChoiceId !== question.field.actualValue()){
					<span>{{labels.Incorrect()}}.</span>
					}
					@else {
					<span>{{labels.Correct()}}.</span>
					}
					<a class="link ms-1"
						(click)="openFlyoutMessage(question.correctChoiceReason)">{{labels.ClickHereToReadWhy()}}</a>
				</div>
			</common-message>
			}

			<common-radio-field [field]="question.field"></common-radio-field>
		</div>
	</div>
	}

	@if(!quiz.attemptSubmittedUTC){
	<common-button-row [center]="true">
		<button class="btn btn-large btn-primary" [disabled]="!checkIfCanSubmit()"
			(click)="submit()">{{labels.button.Submit()}}</button>
	</common-button-row>
	}
	@else if(quiz.countOfAttempts !== 3) {
	<common-button-row [center]="true">
		<button class="btn btn-large btn-primary" (click)="addAttempt()">{{labels.TryAgain()}}</button>
		<a class="link"
			routerLink="../../level/{{quiz.courseLevelStructureId}}">{{labels.NeedToReviewGoBackToOverview()}}</a>
	</common-button-row>
	}
</div>
}