@if(surveyFields(); as surveyFields)
{
<div class="survey-fields-display d-flex flex-column gap-2">
	@for(surveyField of surveyFields; track $index){
	<div class="white-box d-flex flex-column gap-3">
		<h4 [style.backgroundColor]='surveyField.backgroundColor'>
			<span><i class="fa-solid {{surveyField.iconClass}} me-2"></i> {{surveyField.headerLabel}}</span>
		</h4>

		@if(surveyField.yesOrNoQuestionLabel){
		<p>
			<input class="form-check-input fs-6" type="checkbox" name="field-checkbox-" id="acknowledgeCheckbox"
				[ngModel]="surveyField.enabled" (ngModelChange)="setEnabled(surveyField, $event)">
			{{surveyField.yesOrNoQuestionLabel}}
		</p>
		}

		@if(surveyField.enabled){
		@if(surveyField.displayTextLabel){
		<p>{{surveyField.displayTextLabel}}</p>
		}

		@for(field of surveyField.fields; track field.key){
		<div>
			<p class="mb-2">{{field.questionLabel}}</p>
			@if(field.typeAndValue.type === 'Nps'){
			<common-nps-selector [key]="field.key" [value]="field.typeAndValue.value"
				(updateValue)="updateValue(field, $event)"></common-nps-selector>
			}

			@if(field.typeAndValue.type === 'Paragraph'){
			<div class="input-group">
				<span class="input-group-text" [id]="field.key">
					<i class="fa-fw fa-regular fa-pen"></i>
				</span>
				<textarea class="form-control" [id]="field.key"
					placeholder="Textarea{{!field.required? ' (optional)':''}}" [ngModel]='field.typeAndValue.value'
					(ngModelChange)='onInput(field,$event)' style="resize: none;" [style.min-height.em]="8"></textarea>
			</div>
			}
		</div>
		}


		}

	</div>


	@for(subSurveyField of surveyField.subs; track $index){
	<div class="white-box d-flex flex-column gap-3">
		<h4 [style.backgroundColor]='subSurveyField.backgroundColor'>
			<span><i class="fa-solid {{subSurveyField.iconClass}} me-2"></i> {{subSurveyField.headerLabel}}</span>
		</h4>

		@if(subSurveyField.yesOrNoQuestionLabel){
		<p>
			<input class="form-check-input fs-6" type="checkbox" name="field-checkbox-" id="acknowledgeCheckbox"
				[ngModel]="subSurveyField.enabled" (ngModelChange)="setEnabled(subSurveyField, $event)">
			{{subSurveyField.yesOrNoQuestionLabel}}
		</p>
		}

		@if(subSurveyField.enabled){
		@if(subSurveyField.displayTextLabel){
		<p>{{subSurveyField.displayTextLabel}}</p>
		}

		@for(field of subSurveyField.fields; track field.key){
		<div>
			<p class="mb-2">{{field.questionLabel}}</p>
			@if(field.typeAndValue.type === 'Nps'){
			<common-nps-selector [key]="field.key" [value]="field.typeAndValue.value"
				(updateValue)="updateValue(field, $event)"></common-nps-selector>
			}

			@if(field.typeAndValue.type === 'Paragraph'){

			<div class="input-group">
				<span class="input-group-text" [id]="field.key">
					<i class="fa-fw fa-regular fa-pen"></i>
				</span>
				<textarea class="form-control" [id]="field.key"
					placeholder="Textarea{{!field.required? ' (optional)':''}}" [ngModel]='field.typeAndValue.value'
					(ngModelChange)='onInput(field,$event)' style="resize: none;" [style.min-height.em]="8"></textarea>
			</div>
			}
		</div>
		}
		}
	</div>
	}
	}
</div>
}