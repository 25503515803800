import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AuthService } from '../auth.service';


@Component({
	selector: 'common-internal-signed-out',
	imports: [CommonModule],
	templateUrl: './internal-signed-out.widget.html'
})
export class CommonInternalSignedOutWidget {

	private readonly auth = inject(AuthService);

	public signIn() {
		this.auth.internalSignIn();
	}
}
