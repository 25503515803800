import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		BusinessAddress: computed(() => en() ? `Business address` : `{{ ES: Business address }}`),
		Location: computed(() => en() ? `Location` : `{{ ES: Location }}`),
		Deadline: computed(() => en() ? `Deadline` : `{{ ES: Deadline }}`),
		Language: computed(() => en() ? `Language` : `{{ ES: Language }}`),
		Contact: computed(() => en() ? `Contact` : `{{ ES: Contact }}`),
		ClassStartDate: computed(() => en() ? `Class Start Date` : `{{ ES: Class Start Date }}`),
		ProgramsInArea: computed(() => en() ? `Here are the programs in your area` : `{{ ES: Here are the programs in your area }}`),
		MyPendingApplications: computed(() => en() ? `My Pending Applications` : `{{ ES: My Pending Applications }}`),
		ViewOpenApplications: computed(() => en() ? `View Open Applications` : `{{ ES: View Open Applications }}`),
		InfoMsg: computed(() => en() ? '### Apply for an upcoming Pitch Contest\n\n\nThank you for your interest in EforAll. To locate available programs, we need to collect some basic information.This information will never be shared with any external third parties.'
			: '{{ ES: ### Solicite una próxima Pitch Contest\n\n\nGracias por su interés en EforAll.Para localizar los programas disponibles, necesitamos recopilar información básica.Esta información nunca se compartirá con terceros externos.}}'),
	}
}